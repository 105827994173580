import { makeStyles } from '@material-ui/core/styles';
import { Select, Alert } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTenantSelectionList } from '../../queries/tenant/tenant.query';
import { Tenant } from 'app/models/tenant';
import { Auth } from 'aws-amplify';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'app/redux/store';
import { authService } from 'app/services/authService';
import { setTenantDelegateTokens } from 'app/redux/authentication/authentication.slice';
import { setEntitiesSchemas, setCurrentEntity, TenantInfo } from 'app/redux/entity/entity.slice';
import {
  TextField,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  Modal,
} from '@material-ui/core';
import { CardMenu, Button } from '@fabric/ui';
import { Table } from 'antd';
import { useTranslation } from 'react-i18next';
import DeleteIcon from '@material-ui/icons/DeleteOutlined';
import { getTenantEntities, getEntitiesMetadata, deleteEntity } from 'app/services/entityService';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';
import { enqueueSnackbar } from '../../redux/notifier/notifier.slice';
import { getErrorMessage } from '@fabric/ui/src/utils';

interface Entity {
  id: string;
  type: string;
  name: string;
  updated_at: string;
}

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    padding: '36px 40px 0px 35px',
  },
  list: {
    paddingTop: '24px',
  },
  entityAddSection: {
    display: 'flex',
    gap: '5px',
    marginBottom: '10px',
  },
  modal: {
    top: 0,
    right: 0,
    position: 'fixed',
    width: '640px',
    height: '100%',
    background: '#FFFFFF',
  },
  modalHeader: {
    fontFamily: 'Open Sans',
    fontSize: '22px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '20px',
    letterSpacing: '0.25px',
    textAlign: 'center',
  },
  modalMain: {
    marginTop: '20px',
    height: 'calc(100% - 110px)',
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
  },
  detailMain: {
    border: '1px solid #C2CFE0',
    boxSizing: 'border-box',
    borderRadius: '8px',
    height: '100%',
    padding: '10px',
  },
  modalActions: {
    marginTop: '20px',
  },
  cancelBtn: {
    color: '#1c1b1b',
    background: '#c2c1c1',
  },
}));

const ValueSeparator = '::';

const parseTenantValue = (value: string): TenantInfo | undefined => {
  if (!value) return undefined;

  const values = value.split(ValueSeparator);
  return {
    tenantId: values[0],
    tenantSlug: values[1],
  };
};

const EXCLUDE_ENTITY_TYPES = [
  'Partner',
  'PartnerOnlineConfig',
  'PartnerPaymentGateway',
  'MastercardMerchant',
  'PartnerBank',
  'PartnerPatProvisionConfig',
  'PartnerPbAConfig',
  'PartnerPPaaSConfig',
];
const UNDELETABLE_TYPES = ['Tenant'];
const BASE_TYPES = ['Entity', 'Tenant', 'Partner', 'Payee', 'Payor', 'FlowConfig', 'Network'];
const ENTITY_PAGE_SIZE = 50;

const EntityList: React.FunctionComponent = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const [tenantSearch, setTenantSearch] = useState<string>();
  const [currentTenant, setCurrentTenant] = useState<TenantInfo>();
  const [tenantEntities, setTenantEntities] = useState<Array<Entity>>();
  const [selectedEntityTypeToAdd, setSelectedEntityTypeToAdd] = useState<string>();
  const [selectedEntity, setSelectedEntity] = useState<any>();
  const [openEntityDetailModal, setOpenEntityDetailModal] = useState<boolean>(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState<boolean>(false);
  const [confirmation, setConfirmation] = useState<string>('');
  const confirmText = 'confirm delete';

  const { t } = useTranslation();

  const {
    isLoading,
    data,
    isFetching,
    fetchNextPage,
    refetch: fetchActiveTenants,
  } = useTenantSelectionList(tenantSearch);
  const { tenantDelegateTokens } = useSelector((state: RootState) => state && state.authentication);
  const { entitiesSchemas, currentEntity } = useSelector((state: RootState) => state && state.entity);

  const checkAndRenewTenantDelegationToken = async (tenantId: string, tenantDelegateToken: string | undefined) => {
    const isValidToken = authService.isValidToken(tenantDelegateToken);
    if (!isValidToken) {
      const tokens = await authService.generateTenantDelegateTokens();
      dispatch(
        setTenantDelegateTokens({
          tenantId: tenantId,
          accessToken: tokens?.AccessToken,
          idToken: tokens?.IdToken,
        }),
      );
      return tokens?.IdToken;
    }

    return tenantDelegateToken;
  };

  useEffect(() => {
    if (currentEntity?.tenant) {
      setCurrentTenant(currentEntity.tenant);
    }
  }, []);

  useEffect(() => {
    if (!currentTenant || !currentTenant.tenantDelegateToken) {
      setTenantEntities(undefined);
      return;
    }

    checkAndRenewTenantDelegationToken(currentTenant.tenantId, currentTenant.tenantDelegateToken).then((token) => {
      if (token !== currentTenant.tenantDelegateToken) {
        setCurrentTenant({
          ...currentTenant,
          tenantDelegateToken: token,
        });
      } else {
        getTenantEntities(
          currentTenant.tenantId,
          currentTenant.tenantDelegateToken!,
          EXCLUDE_ENTITY_TYPES,
          ENTITY_PAGE_SIZE,
        ).then((entities: Array<Entity>) => {
          setTenantEntities(entities);
          setSelectedEntityTypeToAdd(undefined);
        });

        if (!entitiesSchemas) {
          getEntitiesMetadata(currentTenant.tenantId, currentTenant.tenantDelegateToken!).then((data) => {
            dispatch(setEntitiesSchemas({ schemas: data }));
          });
        }
      }
    });
  }, [currentTenant]);

  const onEntityDeleteClicked = (e: any, entity: any) => {
    e.stopPropagation();
    setSelectedEntity(entity);
    setOpenConfirmDialog(true);
  };

  const handleEntityDelete = () => {
    if (confirmation !== confirmText) {
      return;
    }

    deleteEntity(selectedEntity!.id, currentTenant!.tenantId, currentTenant!.tenantDelegateToken!)
      .then(() => {
        const tenant = currentTenant;
        setCurrentTenant(undefined);
        setCurrentTenant(tenant);
        handleCancel();
      })
      .catch((error) => {
        console.error(error);
        const key = new Date().getTime() + Math.random();
        dispatch(
          enqueueSnackbar({
            notification: {
              key,
              message: getErrorMessage(error),
              options: {
                key: key,
                variant: 'error',
              },
            },
          }),
        );
      });
  };

  const handleConfimrationChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setConfirmation(e.target.value);
  };

  const handleCancel = () => {
    setOpenConfirmDialog(false);
    setSelectedEntity(undefined);
    setConfirmation('');
  };

  const onTenantChanged = (value: string) => {
    const selectedTenant = parseTenantValue(value.trim());
    if (!selectedTenant) {
      return;
    }

    Auth.currentSession().then(async () => {
      let tenantDelegateToken = tenantDelegateTokens?.[selectedTenant.tenantId]?.idToken;
      tenantDelegateToken = await checkAndRenewTenantDelegationToken(selectedTenant.tenantId, tenantDelegateToken);

      setCurrentTenant({
        ...selectedTenant,
        tenantDelegateToken: tenantDelegateToken,
      });
    });
  };

  const handleScroll = (event: any) => {
    const { target } = event;
    if (target.scrollTop + target.offsetHeight > target.scrollHeight - 10) {
      fetchNextPage();
    }
  };

  const onRowClicked = (entity: any, _rowIndex: any, _event: any) => {
    setSelectedEntity(entity);
    setOpenEntityDetailModal(true);
  };

  const handleClose = () => {
    setSelectedEntity(undefined);
    setOpenEntityDetailModal(false);
  };

  const onEntityEditClicked = () => {
    if (!currentTenant || !selectedEntity) {
      return;
    }
    const type = selectedEntity.type;
    const schema = entitiesSchemas?.[type];
    if (!schema) {
      return;
    }

    dispatch(setCurrentEntity({ type: type, schema: schema, data: selectedEntity, tenant: currentTenant }));
    history.push(`/entity/edit/${selectedEntity.id}`);
  };

  const onEntityEditAddClicked = () => {
    if (!currentTenant || !selectedEntityTypeToAdd) {
      return;
    }
    const schema = entitiesSchemas?.[selectedEntityTypeToAdd];
    if (!schema) {
      return;
    }

    dispatch(setCurrentEntity({ type: selectedEntityTypeToAdd, schema: schema, tenant: currentTenant }));
    history.push('/entity/new');
  };

  const getUnkownProperties = (entity: any): string[] => {
    const schema = entitiesSchemas?.[entity.type] as any;
    if (!schema) {
      return Object.keys(entity);
    }

    return Object.keys(entity).filter((key) => !schema.properties[key]);
  };

  const getAbsentTypes = (): string[] => {
    const existingTypes = (tenantEntities || []).map((entity) => entity.type);
    return Object.keys(entitiesSchemas || {}).filter(
      (schemaName) =>
        !BASE_TYPES.includes(schemaName) &&
        !existingTypes.includes(schemaName) &&
        !EXCLUDE_ENTITY_TYPES.includes(schemaName),
    );
  };

  const onSelectedEntityToAdd = (entityType: string) => {
    setSelectedEntityTypeToAdd(entityType);
  };

  const columns = [
    {
      dataIndex: 'type',
      title: 'Entity Type',
      key: 'type',
    },
    {
      dataIndex: 'id',
      title: 'Entity ID',
      key: 'id',
    },
    {
      dataIndex: 'name',
      title: 'Name',
      key: 'name',
    },
    {
      dataIndex: 'updated_at',
      title: 'Last Changed',
      key: 'updated_at',
    },
    {
      title: 'Actions',
      width: 120,
      render: (_text: any, entity: Entity) =>
        !UNDELETABLE_TYPES.includes(entity.type) ? (
          <Box>
            <IconButton
              color="inherit"
              size="small"
              aria-label="delete"
              onClick={(e) => onEntityDeleteClicked(e, entity)}
            >
              <DeleteIcon fontSize="small" />
            </IconButton>
          </Box>
        ) : (
          <React.Fragment></React.Fragment>
        ),
    },
  ];
  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={12} className={classes.list}>
          <Select
            placeholder="Select a Tenant"
            style={{ width: '100%' }}
            onClick={() => {
              fetchActiveTenants();
            }}
            onChange={onTenantChanged}
            onPopupScroll={handleScroll}
            loading={isLoading || isFetching}
            onSearch={(v) => setTenantSearch(v)}
            showSearch={true}
            searchValue={tenantSearch}
            filterOption={false}
            value={currentTenant ? `${currentTenant.tenantId}${ValueSeparator}${currentTenant.tenantSlug}` : undefined}
          >
            {data?.pages.map((page) =>
              page.data.map((t: Tenant) => (
                <Select.Option key={t.id} value={`${t.id}${ValueSeparator}${t.slug}`}>
                  {t.name}
                </Select.Option>
              )),
            )}
          </Select>

          {currentTenant && tenantEntities && (
            <CardMenu title={t('manageEntities')} subtitle={t('manageEntitiesDesc')} actions={[]}>
              <Box className={classes.entityAddSection}>
                <Select
                  placeholder="Select Entity Type to Add"
                  defaultValue={selectedEntityTypeToAdd}
                  onChange={onSelectedEntityToAdd}
                  onSearch={() => {}}
                  showSearch={true}
                  optionFilterProp="label"
                  options={getAbsentTypes().map((entityType) => ({ value: entityType, label: entityType }))}
                  style={{ width: '250px' }}
                />
                {selectedEntityTypeToAdd && (
                  <Button type="submit" variant="contained" onClick={onEntityEditAddClicked}>
                    Add
                  </Button>
                )}
              </Box>
              <Table
                rowKey={'id'}
                columns={columns}
                dataSource={tenantEntities}
                pagination={{ pageSize: ENTITY_PAGE_SIZE }}
                onRow={(entity, rowIndex) => {
                  return {
                    onClick: (event) => {
                      onRowClicked(entity, rowIndex, event);
                    },
                  };
                }}
                size="middle"
              />
            </CardMenu>
          )}

          {openEntityDetailModal && selectedEntity && (
            <Modal
              open={openEntityDetailModal}
              className={classes.modal}
              style={{ inset: 'auto' }}
              onClose={handleClose}
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description"
            >
              <div className={clsx(classes.root, classes.modal)}>
                <Box className={classes.modalHeader}>{selectedEntity.type}</Box>
                <Box className={classes.modalMain}>
                  {!!getUnkownProperties(selectedEntity)?.length && (
                    <Alert
                      message={`The following properties do not exist in ${selectedEntity.type} schema:`}
                      description={getUnkownProperties(selectedEntity).join(', ')}
                      type="warning"
                    />
                  )}
                  <pre className={classes.detailMain}>{JSON.stringify(selectedEntity, null, 2)}</pre>
                </Box>
                <Box className={classes.modalActions}>
                  <Button type="submit" variant="contained" onClick={onEntityEditClicked}>
                    Edit
                  </Button>
                </Box>
              </div>
            </Modal>
          )}
        </Grid>
      </Grid>
      {openConfirmDialog && selectedEntity && (
        <Dialog open={openConfirmDialog}>
          <DialogTitle>Confirm</DialogTitle>
          <DialogContent>
            <DialogContentText>
              This will create new {selectedEntity!.type} Entity for Tenant. Please type "{confirmText}" to proceed.
            </DialogContentText>
            <TextField
              fullWidth
              id="confirmText"
              name="confirmText"
              placeholder={confirmText}
              variant="outlined"
              value={confirmation}
              onChange={handleConfimrationChange}
            />
          </DialogContent>
          <DialogActions>
            <Button type="button" className={classes.cancelBtn} onClick={handleCancel}>
              Cancel
            </Button>
            <Button type="submit" onClick={handleEntityDelete} disabled={confirmation !== confirmText}>
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </div>
  );
};

export default EntityList;
