import React, { useState } from 'react';
import { Select, MenuItem, ListItemText, Checkbox } from '@material-ui/core';
import DataGridFilterColumn from '@fabric/ui/src/components/DataGrid/filterColumn';
export const DropdownSelectionFilter: React.FC<DropdownSelectionFilterProps> = ({
  col,
  values,
  formik,
  setCurrentSelection,
  isUniqueFilter,
}) => {
  const [selectedValues, setSelectedValues] = useState(values[col.field] || []);

  const handleSelectChange = (event: any) => {
    setSelectedValues(event.target.value as string[]);
    if (isUniqueFilter) {
      setCurrentSelection(col.field);
    }
    formik.handleChange(event);
  };

  return (
    <Select
      fullWidth
      multiple
      id={col.field}
      name={col.field}
      value={Array.isArray(selectedValues) ? selectedValues : []}
      onBlur={formik.handleBlur}
      onChange={handleSelectChange}
      displayEmpty={true}
      renderValue={(selected: any) => {
        const labels = Array.isArray(selected) ? selected?.join(', ') : undefined;
        return labels || col.name;
      }}
    >
      {col.values &&
        col.values.map((v) => (
          <MenuItem key={v.value} value={v.value}>
            <Checkbox checked={selectedValues.includes(v.value)} color={'primary'} />
            <ListItemText primary={v.label} />
          </MenuItem>
        ))}
    </Select>
  );
};
interface DropdownSelectionFilterProps {
  col: DataGridFilterColumn;
  values: any;
  formik: any;
  isUniqueFilter?: boolean;
  setCurrentSelection: (selection: string) => void;
}
