import React from 'react';

interface LogoProps {
  className?: string | undefined;
  children?: React.ReactNode;
}

const LogoCompact: React.FunctionComponent<LogoProps> = (props: { className?: string; children?: React.ReactNode }) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 258 288"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
    >
      <path
        d="M99.8382 174.935C90.569 174.935 81.6795 171.219 75.1252 164.604C68.5709 157.99 64.8887 149.019 64.8887 139.664C64.8877 135.032 65.7909 130.445 67.5469 126.166C69.3029 121.886 71.8773 117.998 75.1228 114.722C78.3684 111.447 82.2216 108.849 86.4623 107.077C90.7031 105.305 95.2482 104.393 99.8382 104.394H224.13L246.655 77.8763C272.649 47.2794 251.1 0 211.157 0H46.2604C33.9913 0 22.2248 4.91859 13.5493 13.6737C4.87384 22.4289 0 34.3034 0 46.6851V240.737C0 284.523 53.9892 304.682 82.2263 271.439L164.202 174.935H99.8382Z"
        fill="url(#paint0_linear_676:652)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_676:652"
          x1="296.001"
          y1="-26.7156"
          x2="-1.7395"
          y2="181.067"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.513596" stopColor="#29A370" />
          <stop offset="1" stopColor="#5754EC" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default LogoCompact;
