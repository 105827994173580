import React from 'react';
import { Card, CardActionArea } from '@material-ui/core';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core/';
import { Link as RouterLink, useLocation } from 'react-router-dom';

interface CardMenuProps {
  title?: string;
  subtitle?: string;
  children?: React.ReactNode;
  className?: string | undefined;
  actions?: React.ReactNode[];
  isMenu?: boolean;
  to?: string;
  onClick?: () => void;
  isActive?: boolean;
}

const useStyles = makeStyles({
  title: (props: any) => ({
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    color: '#141E61',
    fontWeight: 600,
    fontSize: '22px',
    lineHeight: '18px',
    letterSpacing: '0.1px',
    ...(!props.nonSubTitle && { paddingTop: '13px' }),
    paddingLeft: '9px',
  }),
  subTitle: {
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '12px',
    lineHeight: '18px',
    letterSpacing: '0.1px',
    color: 'rgba(120, 122, 145, 0.6)',
    paddingLeft: '9px',
    paddingRight: '11px',
    paddingTop: '14.65px',
  },
  divider: {
    paddingBottom: '0px',
    size: '4 !important',
    color: '#29A370',
  },
  border: {
    borderBottom: '5px solid #11823B',
    textDecoration: 'none',
  },
});

const CardMenu: React.FunctionComponent<CardMenuProps> = (props) => {
  const classes = useStyles({ nonSubTitle: !props.subtitle });
  const location = useLocation();

  const isActive = props.isActive || location?.pathname === props.to;

  return (
    <Box className={clsx(props.isMenu, props.className)}>
      {props.isMenu && props.to ? (
        <RouterLink to={props.to}>
          <CardActionArea className={clsx('h-full')}>
            <Card
              elevation={6}
              className={isActive ? clsx('px-6 py-5 h-full', classes.border) : clsx('px-6 py-5 h-full')}
            >
              <Box display="flex">
                <Box flexGrow={1}>
                  <div className={clsx(classes.title, 'card-title')}>{props.title}</div>
                  {props.subtitle && (
                    <div className={clsx(classes.subTitle, 'card-subtitle', 'mb-4')}>{props.subtitle}</div>
                  )}
                </Box>
                <Box p={1}>
                  {props.actions?.map((child, index) => (
                    <Box flexShrink={0} key={index}>
                      {child}
                    </Box>
                  ))}
                </Box>
              </Box>
              {props.children}
            </Card>
          </CardActionArea>
        </RouterLink>
      ) : (
        <Card elevation={6} className={clsx('px-6 py-5 h-full')}>
          <Box display="flex" flexDirection="row">
            <Box flexGrow={1}>
              <div className={clsx(classes.title, 'card-title')}>{props.title}</div>
              {props.subtitle && (
                <div className={clsx(classes.subTitle, 'card-subtitle', ' mb-4')}>{props.subtitle}</div>
              )}
            </Box>
            <Box display="flex" p={1} flexDirection="row">
              {props.actions?.map((child, index) => (
                <Box key={index} p={1} flexShrink={0}>
                  {child}
                </Box>
              ))}
            </Box>
          </Box>
          {props.children}
        </Card>
      )}
    </Box>
  );
};

export default CardMenu;
