/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types

export const convertHexToRGB = (hex: string) => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
};
export function isMobile(): boolean {
  if (window) {
    return window.matchMedia(`(max-width: 767px)`).matches;
  }
  return false;
}

export const isMdScreen = (): boolean => {
  if (window) {
    return window.matchMedia(`(max-width: 1199px)`).matches;
  }
  return false;
};

export const classList = (classes: { [s: string]: unknown } | ArrayLike<unknown>) => {
  return Object.entries(classes)
    .filter((entry) => entry[1])
    .map((entry) => entry[0])
    .join(' ');
};

export const extractTenantCode = (hostname: string) => {
  const arr = hostname.split('.');
  return arr.length > 0 ? arr[0] : '';
};

export const getLabelFromValueDropdownSelection = (values: { label?: string; value?: string }[], value: string) => {
  const selected = values.find((v) => v.value === value);
  return selected?.label;
};

export const getErrorMessage = (error: any) => {
  const details = error?.response?.data?.details;
  if (details && details.length > 0) {
    return details.map((detailError: any) => detailError.message).join(', ');
  }

  const errorMessage = error?.response?.data?.message;
  if (errorMessage) {
    return errorMessage;
  }

  return error?.message || 'Network error';
};
