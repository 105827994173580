/* eslint-disable @typescript-eslint/no-explicit-any */
import DataGridFilterColumn from '@fabric/ui/src/components/DataGrid/filterColumn';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Tenant } from 'app/models/tenant';
import { Paging } from 'app/models/paging';

export interface TenantState {
  tenants: Tenant[];
  tenantsMenu: Tenant[];
  searchTenant: Tenant[];
  selected?: Tenant;
  paging: Paging;
}

const initialState: TenantState = {
  tenants: [],
  searchTenant: [],
  tenantsMenu: [],
  paging: {
    page_size: 10,
    page: 1,
  },
};

const tenant = createSlice({
  name: `tenant`,
  initialState,
  reducers: {
    getTenantsSuccess: (state, action: PayloadAction<{ data: any; metadata: any }>) => {
      const { data, metadata } = action.payload;
      state.tenants = data;
      state.paging = metadata;
    },
    getTenantsMenuSuccess: (
      state,
      action: PayloadAction<{
        data: any;
        criterias?: DataGridFilterColumn[];
      }>,
    ) => {
      const { data, criterias } = action.payload;
      if (criterias && criterias.length > 0) {
        state.searchTenant = [...data];
        state.tenantsMenu = [];
      } else {
        state.searchTenant = [];
        state.tenantsMenu = [...state.tenantsMenu, ...data];
      }
    },
    getTenantDetailsSuccess: (state, action: PayloadAction<{ data: Tenant }>) => {
      const { data } = action.payload;
      state.selected = data;
    },
  },
});

export const { getTenantsSuccess, getTenantsMenuSuccess, getTenantDetailsSuccess } = tenant.actions;

export default tenant.reducer;
