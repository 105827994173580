/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, TextField, Box, Typography, MenuItem } from '@material-ui/core/';
import { CardMenu, FieldContainer, Button, BoxInput } from '@fabric/ui';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { createTenantAction } from '../../redux/tenant/tenant.actions';
import { tenantSchema } from './validation.schema';
import { generateTenantSlugFromName } from '../../../utils';
import { useHistory } from 'react-router-dom';
import { sanitizeObject } from '@fabric/ui/src/components/utils/utils';
import { allBillingTypes, billingCurrencies, billingPeriodUnits } from '../../constants';
// import clsx from 'clsx';
// import AddCircleIcon from '@material-ui/icons/AddCircle';

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    padding: '36px 40px 0px 35px',
  },
  btn: {
    paddingBottom: '20px',
    paddingTop: '28px',
  },
  userGroup: {
    marginTop: '20px',
  },
  userGroupBox: {
    padding: '20px',
  },
  userGroupList: {},
  permissions: {
    padding: '20px',
  },
  formControl: {
    margin: '5px',
  },
  section: {
    boxShadow: '0px 6px 18px rgba(0, 0, 0, 0.06)',
    marginTop: '45px',
  },
  lastSection: {
    marginBottom: '41px',
  },
  sectionName: {
    color: '#2E4163',
    fontFamily: 'DM Sans',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '21px',
    letterSpacing: '0.2px',
  },
  action: {
    color: 'rgba(46, 65, 99, 0.7)',
    fontFamily: 'DM Sans',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '18px',
    letterSpacing: '0.2px',
    textTransform: 'capitalize',
  },
  pocTitle: {
    paddingLeft: '18px',
    paddingTop: '30px',
  },
  poc: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '18px',
    letterSpacing: '0.1px',
    color: '#141E61',
  },
}));

const TenantCreate: React.FunctionComponent = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const validationSchema = tenantSchema;

  const submitForm = () => {
    const tenant = sanitizeObject({ ...values });
    tenant.slug = generateTenantSlugFromName(tenant.name);
    dispatch(createTenantAction({ tenant, history }));
  };

  const formik = useFormik({
    initialValues: {
      id: '',
      name: '',
      slug: '',
      website_url: '',
      address: '',
      admin_first_name: '',
      admin_last_name: '',
      admin_email: '',
      admin_phone: '',
      admin_designation: '',
      billing_type: '',
      billing_currency: '',
      billing_period_unit: '',
      billing_period_length: 1,
      billings: [],
      contacts: [],
      is_active: true,
    },
    validationSchema: validationSchema,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: submitForm,
  });

  const { values, errors, touched, handleSubmit } = formik;

  return (
    <div className={classes.root}>
      <CardMenu title={t('tenantCreate')} subtitle={t('tenantCreateDesc')}>
        <BoxInput>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={4}>
                <FieldContainer>
                  <TextField
                    fullWidth
                    id="name"
                    name="name"
                    label={`${t('tenant.companyName')} (*)`}
                    value={values.name}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    error={touched.name && Boolean(errors.name)}
                    helperText={touched.name && errors.name}
                    inputProps={{ tabIndex: 1 }}
                  />
                </FieldContainer>
              </Grid>
              <Grid item xs={4}>
                <FieldContainer>
                  <TextField
                    fullWidth
                    id="website_url"
                    name="website_url"
                    label={`${t('tenant.companyWebsite')} (*)`}
                    value={values.website_url}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    error={touched.website_url && Boolean(errors.website_url)}
                    helperText={touched.website_url && errors.website_url}
                    inputProps={{ tabIndex: 2 }}
                  />
                </FieldContainer>
              </Grid>
              <Grid item xs={4}>
                <FieldContainer>
                  <TextField
                    fullWidth
                    id="address"
                    name="address"
                    label={`${t('tenant.companyAddress')} (*)`}
                    value={values.address}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    error={touched.address && Boolean(errors.address)}
                    helperText={touched.address && errors.address}
                    inputProps={{ tabIndex: 3 }}
                  />
                </FieldContainer>
              </Grid>
            </Grid>
            <Box className={classes.pocTitle}>
              <Typography className={classes.poc}>Company Point of Contact</Typography>
            </Box>
            <Grid container spacing={3}>
              <Grid item xs={4}>
                <FieldContainer>
                  <TextField
                    fullWidth
                    id="adminFirstName"
                    name="admin_first_name"
                    label={`${t('tenant.firstName')} (*)`}
                    value={values.admin_first_name}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    error={touched.admin_first_name && Boolean(errors.admin_first_name)}
                    helperText={touched.admin_first_name && errors.admin_first_name}
                    inputProps={{ tabIndex: 4 }}
                  />
                </FieldContainer>
                <FieldContainer>
                  <TextField
                    fullWidth
                    id="role"
                    name="admin_designation"
                    label={t('tenant.designation')}
                    value={values.admin_designation}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={touched.admin_designation && Boolean(errors.admin_designation)}
                    helperText={touched.admin_designation && errors.admin_designation}
                    inputProps={{ tabIndex: 7 }}
                  />
                </FieldContainer>
              </Grid>
              <Grid item xs={4}>
                <FieldContainer>
                  <TextField
                    fullWidth
                    id="adminLastName"
                    name="admin_last_name"
                    label={`${t('tenant.lastName')} (*)`}
                    value={values.admin_last_name}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    error={touched.admin_last_name && Boolean(errors.admin_last_name)}
                    helperText={touched.admin_last_name && errors.admin_last_name}
                    inputProps={{ tabIndex: 5 }}
                  />
                </FieldContainer>
                <FieldContainer>
                  <TextField
                    fullWidth
                    id="adminPhone"
                    name="admin_phone"
                    label={`${t('tenant.phoneNumber')} (*)`}
                    value={values.admin_phone}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    error={touched.admin_phone && Boolean(errors.admin_phone)}
                    helperText={touched.admin_phone && errors.admin_phone}
                    inputProps={{ tabIndex: 8 }}
                  />
                </FieldContainer>
              </Grid>
              <Grid item xs={4}>
                <FieldContainer>
                  <TextField
                    fullWidth
                    id="admin_email"
                    name="admin_email"
                    label={`${t('tenant.emailID')} (*)`}
                    value={values.admin_email}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    error={touched.admin_email && Boolean(errors.admin_email)}
                    helperText={touched.admin_email && errors.admin_email}
                    inputProps={{ tabIndex: 6 }}
                  />
                </FieldContainer>
              </Grid>
            </Grid>
            <Box className={classes.pocTitle}>
              <Typography className={classes.poc}>Billing Info</Typography>
            </Box>
            <Grid container spacing={3}>
              <Grid item xs={4}>
                <FieldContainer>
                  <TextField
                    fullWidth
                    select
                    id="billing_type"
                    name="billing_type"
                    label={`${t('tenant.billingType')} (*)`}
                    value={values.billing_type}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    error={touched.billing_type && Boolean(errors.billing_type)}
                    helperText={touched.billing_type && errors.billing_type}
                    inputProps={{ tabIndex: 9 }}
                  >
                    {allBillingTypes.map((v) => (
                      <MenuItem value={v.value}>{v.label}</MenuItem>
                    ))}
                  </TextField>
                </FieldContainer>
                <FieldContainer className={classes.btn}>
                  <Button variant="contained" type="submit" tabIndex={10}>
                    {t('buttons.create')}
                  </Button>
                </FieldContainer>
              </Grid>
              <Grid item xs={4}>
                <FieldContainer>
                  <TextField
                    fullWidth
                    select
                    id="billing_currency"
                    name="billing_currency"
                    label={`${t('tenant.billingCurrency')} (*)`}
                    value={values.billing_currency}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    error={touched.billing_currency && Boolean(errors.billing_currency)}
                    helperText={touched.billing_currency && errors.billing_currency}
                    inputProps={{ tabIndex: 9 }}
                  >
                    {billingCurrencies.map((v) => (
                      <MenuItem value={v}>{v}</MenuItem>
                    ))}
                  </TextField>
                </FieldContainer>
              </Grid>
              <Grid item xs={4}>
                <FieldContainer>
                  <TextField
                    fullWidth
                    select
                    id="billing_period_unit"
                    name="billing_period_unit"
                    label={`${t('tenant.billingPeriodUnit')} (*)`}
                    value={values.billing_period_unit}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    error={touched.billing_period_unit && Boolean(errors.billing_period_unit)}
                    helperText={touched.billing_period_unit && errors.billing_period_unit}
                    inputProps={{ tabIndex: 9 }}
                  >
                    {billingPeriodUnits.map((v) => (
                      <MenuItem value={v}>{v}</MenuItem>
                    ))}
                  </TextField>
                </FieldContainer>
              </Grid>
            </Grid>
          </form>
        </BoxInput>
      </CardMenu>
      {/*<CardMenu*/}
      {/*    title={t('billingSetup')}*/}
      {/*    subtitle={t('billingSetupDesc')}*/}
      {/*    className={clsx(classes.section)}*/}
      {/*    actions={[*/}
      {/*        <IconButton*/}
      {/*            color="primary"*/}
      {/*            aria-label="add to shopping cart"*/}
      {/*            key={1}*/}
      {/*        >*/}
      {/*            <AddCircleIcon fontSize="large" />*/}
      {/*        </IconButton>,*/}
      {/*    ]}*/}
      {/*/>*/}
      {/*<CardMenu*/}
      {/*    title={t('primaryAdminAccount')}*/}
      {/*    subtitle={t('primaryAdminAccountDesc')}*/}
      {/*    className={clsx(classes.section)}*/}
      {/*    actions={[*/}
      {/*        <IconButton*/}
      {/*            color="primary"*/}
      {/*            aria-label="add to shopping cart"*/}
      {/*            key={1}*/}
      {/*        >*/}
      {/*            <AddCircleIcon fontSize="large" />*/}
      {/*        </IconButton>,*/}
      {/*    ]}*/}
      {/*/>*/}
      {/*<CardMenu*/}
      {/*    title={t('accountManagers')}*/}
      {/*    subtitle={t('accountManagersDesc')}*/}
      {/*    className={clsx(classes.section, classes.lastSection)}*/}
      {/*    actions={[*/}
      {/*        <IconButton*/}
      {/*            color="primary"*/}
      {/*            aria-label="add to shopping cart"*/}
      {/*            key={1}*/}
      {/*        >*/}
      {/*            <AddCircleIcon fontSize="large" />*/}
      {/*        </IconButton>,*/}
      {/*    ]}*/}
      {/*/>*/}
    </div>
  );
};

export default TenantCreate;
