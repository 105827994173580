import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'app/redux/store';
import { useCallback } from 'react';
import { find } from 'lodash';
import { Request } from 'app/models/request';
import * as actions from 'app/redux/ui/ui.actions';

const useUI = () => {
  const dispatch = useDispatch();
  const { requests } = useSelector((state: RootState) => state && state.ui);
  const getRequestByName = useCallback(
    ({ name }) => {
      return find(requests, (r: Request) => r.name === name);
    },
    [requests],
  );

  const requestStarted = useCallback(
    ({ name }) => {
      dispatch(actions.requestStartedAction({ name }));
    },
    [dispatch],
  );
  return { requests, getRequestByName, requestStarted };
};

export default useUI;
