import { PatLinkPath, RouteConfigWrapper } from '@fabric/ui/src/commons';
import PatLinkDelegateAccess from './PatLinkDelegateAccess';

const patLinkRoutes: RouteConfigWrapper[] = [
  {
    route: {
      path: PatLinkPath,
      exact: true,
      component: () => <PatLinkDelegateAccess />,
    },
  },
];

export default patLinkRoutes;
