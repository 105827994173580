import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Layout from 'app/components/Layout/Layout';
import sessionRoutes from 'app/pages/Sessions/routes';
import AuthGuard from 'app/pages/AuthGuard';
const RouterConfig: React.FunctionComponent = () => {
  return (
    <Switch>
      {sessionRoutes.map((item, i) => (
        <Route key={i} path={item.path} component={item.component} />
      ))}
      <AuthGuard>
        <Layout />
      </AuthGuard>
    </Switch>
  );
};

export default RouterConfig;
