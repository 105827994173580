const Layout1Settings = {
  leftSidebar: {
    show: true,
    mode: 'full',
    theme: 'white',
    bgImgURL: '/assets/images/sidebar/sidebar-bg-dark.jpg',
  },
  topbar: {
    show: true,
    fixed: true,
    theme: 'white',
  },
};

export default Layout1Settings;
