/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { IconButton } from '@material-ui/core/';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/DeleteOutlined';
import { GridApi, GridColDef, GridRowId, DataGrid as CoreGrid, GridComponentProps } from '@mui/x-data-grid';
import { hasValue } from '../utils/utils';

const useStyles = makeStyles((props: { palette?: any }) => ({
  root: {},
  actionRoot: {
    display: 'inline-flex',
    alignItems: 'center',
  },
  textPrimary: {
    color: props.palette?.text.white,
  },
  textUnderline: {
    textDecoration: 'underline',
  },
}));

interface RowMenuProps {
  api: GridApi;
  id: GridRowId;
  onEdit?: (row: any) => void;
  onDelete?: (row: any) => void;
  onOpen?: (row: any) => void;
  actions?: ActionType[];
}

function RowMenuCell(props: RowMenuProps) {
  const { api, id, onEdit, onDelete, onOpen, actions } = props;
  const classes = useStyles();
  const handleEditClick = (event: any) => {
    event.preventDefault();
    onEdit && onEdit(api.getRow(id));
  };
  const handleDeleteClick = (event: any) => {
    event.preventDefault();
    onDelete && onDelete(api.getRow(id));
  };
  const handleOpenClick = (event: any) => {
    event.preventDefault();
    onOpen && onOpen(api.getRow(id));
  };
  return (
    <div className={classes.actionRoot}>
      {actions && hasValue(actions, 'edit') && (
        <IconButton color="inherit" size="small" aria-label="edit" onClick={handleEditClick}>
          <EditIcon fontSize="small" />
        </IconButton>
      )}
      {actions && hasValue(actions, 'delete') && (
        <IconButton color="inherit" size="small" aria-label="delete" onClick={handleDeleteClick}>
          <DeleteIcon fontSize="small" />
        </IconButton>
      )}
      {actions && hasValue(actions, 'view') && (
        <span className={clsx(classes.textUnderline)}>
          <a href="#" onClick={handleOpenClick}>
            Open
          </a>
        </span>
      )}
    </div>
  );
}

export type ActionType = 'edit' | 'delete' | 'view';

interface DataGridProps
  extends Omit<
    GridComponentProps,
    | 'apiRef'
    | 'checkboxSelectionVisibleOnly'
    | 'disableColumnResize'
    | 'disableColumnReorder'
    | 'disableMultipleColumnsFiltering'
    | 'disableMultipleColumnsSorting'
    | 'disableMultipleSelection'
    | 'options'
    | 'onRowsScrollEnd'
    | 'onViewportRowsChange'
    | 'pagination'
    | 'scrollEndThreshold'
    | 'signature'
  > {
  className?: string;
  actions?: ActionType[];
  onEdit?: (row: unknown) => void;
  onDelete?: (row: unknown) => void;
  onOpen?: (row: any) => void;
}

const DataGrid: React.FunctionComponent<DataGridProps> = ({
  className,
  actions,
  onEdit,
  onDelete,
  onOpen,
  ...props
}: DataGridProps) => {
  const classes = useStyles();

  const actionColumn: GridColDef = {
    field: 'actions',
    headerName: 'Actions',
    renderCell: (params) => {
      let rmp: RowMenuProps = { ...params, ...{ actions } };
      if (onEdit) {
        rmp = {
          ...rmp,
          ...{
            onEdit: onEdit,
          },
        };
      }

      if (onDelete) {
        rmp = {
          ...rmp,
          ...{
            onDelete: onDelete,
          },
        };
      }

      if (onOpen) {
        rmp = {
          ...rmp,
          ...{
            onOpen: onOpen,
          },
        };
      }

      return RowMenuCell(rmp);
    },
    sortable: false,
    width: 100,
    headerAlign: 'center',
    filterable: false,
    align: 'center',
    disableColumnMenu: true,
    disableReorder: true,
  };

  if (actions && actions.length > 0) {
    props.columns = [...props.columns, actionColumn];
  }

  return (
    <div style={{ display: 'flex', height: '100%' }}>
      <div style={{ flexGrow: 1 }}>
        <CoreGrid pagination className={clsx(classes.root, className)} {...props} />
      </div>
    </div>
  );
};

export default DataGrid;
