import { createSlice } from '@reduxjs/toolkit';

export interface InitApp {
  isStarted: boolean;
}

const initialState: InitApp = {
  isStarted: false,
};

const initSlice = createSlice({
  name: 'init',
  initialState,
  reducers: {
    getInitSuccess: (state) => {
      state.isStarted = true;
    },
    getInit: (state) => {
      state.isStarted = false;
    },
  },
});

export const { getInitSuccess, getInit } = initSlice.actions;

export default initSlice.reducer;
