/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable no-useless-escape */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
import { trim } from 'lodash';
import { parseISO } from 'date-fns';
import { format, utcToZonedTime } from 'date-fns-tz';
import { SorterResult } from 'antd/lib/table/interface';
import _ from 'lodash';

const MAX_LENGTH_URL_DISPLAY = 30;
const MAX_LENGTH_SLUG = 30;
const { timeZone } = Intl.DateTimeFormat().resolvedOptions();

export const phoneNumberRegex = /^\+(?:[0-9]●?){6,14}[0-9]$/g;
export const staffIdRegex = /^[\S]*$/;
export const convertHexToRGB = (hex: string) => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
};

export function isMobile(): boolean {
  if (window) {
    return window.matchMedia('(max-width: 767px)').matches;
  }
  return false;
}

export const isMdScreen = (): boolean => {
  if (window) {
    return window.matchMedia('(max-width: 1199px)').matches;
  }
  return false;
};

export const classList = (classes: { [s: string]: unknown } | ArrayLike<unknown>) => {
  return Object.entries(classes)
    .filter((entry) => entry[1])
    .map((entry) => entry[0])
    .join(' ');
};

export const extractTenantCode = (hostname: string) => {
  const arr = hostname.split('.');
  return arr.length > 0 ? arr[0] : '';
};

function slugify(str: string) {
  const a = 'àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìıİłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;';
  const b = 'aaaaaaaaaacccddeeeeeeeegghiiiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------';
  const p = new RegExp(a.split('').join('|'), 'g');

  return str
    .toString()
    .toLowerCase()
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(p, (c) => b.charAt(a.indexOf(c))) // Replace special characters
    .replace(/&/g, '-and-') // Replace & with 'and'
    .replace(/[^\w\-]+/g, '') // Remove all non-word characters
    .replace(/\-\-+/g, '-') // Replace multiple - with single -
    .replace(/^-+/, '') // Trim - from start of text
    .replace(/-+$/, ''); // Trim - from end of text
}

export const generateTenantSlugFromName = (name: string, maxLength: number = MAX_LENGTH_SLUG) => {
  if (!name) {
    return name;
  }

  const slug = slugify(name);

  if (slug.length > maxLength) return trim(slug.slice(0, maxLength), '-');

  return slug;
};

export const deepMerge = (a: any, b: any) => {
  // Variables
  const target = {};
  const deep = true;

  // Merge the object into the target object
  const merger = (obj: any) => {
    for (const prop in obj) {
      if (obj.hasOwnProperty(prop)) {
        if (deep && Object.prototype.toString.call(obj[prop]) === '[object Object]') {
          // If we're doing a deep merge and the property is an object
          target[prop] = deepMerge(target[prop], obj[prop]);
        } else {
          // Otherwise, do a regular merge
          target[prop] = obj[prop];
        }
      }
    }
  };

  // Loop through each object and conduct a merge
  merger(a);
  merger(b);

  return target;
};

const formatInTimeZone = (date: Date | string | number, fmt: string, tz: string) =>
  format(utcToZonedTime(date, tz), fmt, { timeZone: tz });

export const toClientTimestamp = (datetime: string) => {
  // 2021-12-20T01:55:24.370Z
  const d = parseISO(datetime);
  return formatInTimeZone(d, 'dd MMM yyyy, p', 'UTC');
};

export function buildSortUrl(url: string, sort?: SorterResult<any>[]) {
  if (!sort || sort.length < 1) {
    return url;
  }

  const sortStr = sort
    .filter((s) => s.order)
    .reduceRight((pre, current, currentIndex) => {
      const operator = current.order === 'descend' ? '-' : '+';
      return `${pre}${operator}${current.field}${currentIndex === 0 ? '' : ','}`;
    }, '');

  if (!sortStr || sortStr.length < 1) {
    return url;
  }

  return `${url}&sort=${encodeURIComponent(sortStr)}`;
}

export const BreakDateRange = '_';

export function getStringDate(date: Date | undefined | null): string {
  if (!date) {
    return '';
  }
  return date.toISOString();
}

export enum DateEnum {
  START,
  END,
}

export function isDate(d: Date) {
  if (Object.prototype.toString.call(d) === '[object Date]') {
    return !isNaN(d.getTime());
  }
  return false;
}

export function textAbstract(text: string) {
  if (!text || text.length < MAX_LENGTH_URL_DISPLAY) {
    return text;
  }
  return `${text.substring(0, MAX_LENGTH_URL_DISPLAY)}...`;
}

export function getFooterOfPagination(metadata?: { page: number; total: number; page_size: number }) {
  if (!metadata) {
    return '';
  }
  const { page, page_size, total } = metadata;
  const firstEntry = (page - 1) * page_size + 1;
  const lastEntry = total < page * page_size ? total : page * page_size;
  return `${firstEntry} - ${lastEntry} of ${total || 0}`;
}

export const toLocalClientTimestamp = (datetime: string) => {
  const d = parseISO(datetime);
  return formatInTimeZone(d, 'dd MMM yyyy, p', timeZone);
};

export function convertToStartCase(data: string | undefined) {
  return _.startCase(_.camelCase(data || ''));
}
