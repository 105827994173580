import { apiClient } from 'app/commons/axios';
import DataGridFilterColumn from '@fabric/ui/src/components/DataGrid/filterColumn';
import { Paging } from 'app/models/paging';
import { urlFromSearchCriteria } from '@fabric/ui';
import { PaginationAuditLogResult } from '../pages/Audit/audit.interface';
import { SorterResult } from 'antd/lib/table/interface';
import { AuditLogV2 } from '../models/audit';
import { buildSortUrl } from '../../utils';

export const getAuditLogs = async (
  { page_size, page }: Paging,
  criterias?: DataGridFilterColumn[],
  sort?: SorterResult<AuditLogV2>[],
) => {
  let url = `/v1/audit_logs?page=${page}&page_size=${page_size}&actor_type=osp_user`;
  url = urlFromSearchCriteria(url, criterias);
  url = buildSortUrl(url, sort);

  return apiClient.get<PaginationAuditLogResult>(url);
};
