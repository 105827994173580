import React, { Fragment } from 'react';
import Menu from '@material-ui/core/Menu';
import { ThemeProvider, makeStyles } from '@material-ui/styles';
import useSettings from '../../hooks/useSettings';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const useStyles = makeStyles((props: { palette?: any }) => ({
  menuButton: {
    display: 'inline-block',
    color: props.palette?.text.primary,
    '& div:hover': {
      backgroundColor: props.palette?.action.hover,
    },
  },
}));

const CustomMenu: React.FunctionComponent<{
  menuButton: React.ReactNode;
  shouldCloseOnItemClick?: boolean;
  horizontalPosition?: number | 'left' | 'right' | 'center';
  children?: React.ReactNode;
}> = (props) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const children = React.Children.toArray(props.children);
  const { shouldCloseOnItemClick = true, horizontalPosition = 'left' } = props;
  const { settings } = useSettings();
  const classes = useStyles();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    shouldCloseOnItemClick && setAnchorEl(null);
  };

  return (
    <Fragment>
      <div className={classes.menuButton} onClick={handleClick}>
        {props.menuButton}
      </div>
      <ThemeProvider theme={settings.themes[settings.activeTheme]}>
        <Menu
          elevation={8}
          getContentAnchorEl={null}
          anchorEl={anchorEl}
          open={!!anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: horizontalPosition,
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: horizontalPosition,
          }}
        >
          {children.map((child, index) => (
            <div onClick={handleClose} key={index}>
              {child}
            </div>
          ))}
        </Menu>
      </ThemeProvider>
    </Fragment>
  );
};

export default CustomMenu;
