/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import { Icon, Divider, Box } from '@material-ui/core';
import TouchRipple from '@material-ui/core/ButtonBase';
import VerticalNavExpansionPanel from './VerticalNavExpansionPanel';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import useSettings from '../../hooks/useSettings';
import { Skeleton } from '@material-ui/lab';

const useStyles = makeStyles((props?: { palette?: any }) => ({
  navItem: {
    transition: 'all 150ms ease-in',
    color: '#334D6E',
    '& .material-icons-outlined': {
      color: '#C2CFE0',
    },
    '&:hover': {
      backgroundColor: props?.palette?.action.hover,
    },
  },
  navItemActive: {
    color: '#29A370',
    '& .material-icons-outlined': {
      color: '#29A370',
    },
  },
  compactNavItem: {
    overflow: 'hidden',
    justifyContent: 'center !important',
    '& $itemText': {
      display: 'none',
    },
    '& $itemIcon': {
      display: 'none',
    },
  },
  itemIcon: {},
  itemText: {
    fontFamily: 'Poppins',
    fontWeight: 500,
    fontSize: '13px',
    paddingLeft: '16.5px',
    lineHeight: '20px',
    letterSpacing: '0.1px',
    wordWrap: 'break-word',
    whiteSpace: 'normal',
  },
  label: {
    color: props?.palette?.text.textualDarkBLue,
  },
  bulletIcon: {
    background: props?.palette?.text.secondary,
  },
  selection: {
    width: '100%',
    background: '#F5F5F5',
  },
  admin: {
    paddingTop: '10px',
    paddingBottom: '10px',
  },
  openFabric: {
    height: '64px',
    fontWeight: 600,
    fontSize: '13px',
    lineHeight: '19px',
    letterSpacing: '0.01em',

    color: '#192A3E',
    background: '#F5F5F5',
    margin: 'auto',
  },
  administration: {
    fontWeight: 300,
    fontSize: '13px',
    lineHeight: '19px',
    /* identical to box height */

    letterSpacing: '0.01em',

    color: '#787A91',
    paddingLeft: '17px',
  },
  disabledLink: { pointerEvents: 'none' },
}));

export interface ItemMenu {
  type?: string;
  label?: string;
  path?: any;
  name?: string;
  icon?: string;
  children?: ItemMenu[];
  iconText?: string;
  badge?: any;
  component?: React.FunctionComponent;
  _uid?: string;
  permission?: string[];
  text?: string;
}

const VerticalNav = (props: { items?: ItemMenu[] }) => {
  const { items } = props;
  const { settings, updateSettings } = useSettings();
  const { mode } = settings.layout1Settings.leftSidebar;
  const classes = useStyles();

  const renderComponent = (c: ItemMenu) => {
    if (c.component) {
      return React.createElement(c.component, {
        key: c._uid,
      });
    }
    return <></>;
  };

  const renderLevels = (data: ItemMenu[] | undefined): React.ReactNode => {
    if (!data || data.length < 1) {
      return [1, 2, 3, 4, 5].map((_item, index) => {
        return (
          <Skeleton
            key={index}
            className={clsx({
              'flex justify-between h-44 border-radius-4 mb-2 compactNavItem whitespace-pre overflow-hidden': true,
              [classes.label]: true,
              hidden: mode === 'compact',
            })}
          ></Skeleton>
        );
      });
    }
    return (
      data &&
      data.map((item, index) => {
        if (item.type === 'label')
          return (
            <p
              key={index}
              className={clsx({
                'px-4 mb-2 mt-6 uppercase text-12 sidenavHoverShow': true,
                [classes.label]: true,
                hidden: mode === 'compact',
              })}
            >
              {item.label}
            </p>
          );

        if (item.type === 'open-fabric') {
          return (
            <Box className={classes.openFabric} key={index}>
              <Box width="100%" display="flex" paddingTop="15px" paddingLeft="15px">
                Open Fabric
              </Box>
              <Box width="100%" display="flex" className={classes.administration}>
                Administration
              </Box>
            </Box>
          );
        }
        if (item.type === 'divider') {
          return <Divider key={index} />;
        }

        if (item.type === 'selection' && item.component) {
          return (
            <div className={classes.selection} key={index}>
              {renderComponent(item)}
              {item.text && (
                <Box className={classes.admin} display="flex" justifyContent="center">
                  {item.text}
                </Box>
              )}
            </div>
          );
        }
        if (item.children) {
          return (
            <VerticalNavExpansionPanel mode={mode} item={item} key={index}>
              {renderLevels(item.children)}
            </VerticalNavExpansionPanel>
          );
        } else {
          if (item.type === 'tenant-delegate' && (!settings.seletedTenantId || settings.seletedTenantId.length < 1)) {
            return (
              <NavLink
                key={index}
                to={item.path}
                activeClassName={classes.navItemActive}
                className={clsx(
                  {
                    'flex justify-between h-44 border-radius-4 mb-2 compactNavItem whitespace-pre overflow-hidden':
                      true,
                    [classes.navItem]: true,
                    [classes.compactNavItem]: mode === 'compact',
                  },
                  classes.disabledLink,
                )}
              >
                <TouchRipple key={item.name} name="child" className="w-full">
                  {item?.icon ? (
                    <Icon key={item.name} className="material-icons-outlined text-18 align-middle w-36 px-4">
                      {item.icon}
                    </Icon>
                  ) : (
                    <Fragment>
                      <div
                        className={clsx({
                          'nav-bullet p-2px rounded ml-6 mr-2': true,
                          [classes.bulletIcon]: true,
                          hidden: mode === 'compact',
                        })}
                      />
                      <div
                        className={clsx({
                          'nav-bullet-text ml-5 text-11': true,
                          hidden: mode !== 'compact',
                        })}
                      >
                        {item.iconText}
                      </div>
                    </Fragment>
                  )}
                  <span className={clsx('align-middle text-left sidenavHoverShow', classes.itemText)}>{item.name}</span>
                  <div className="mx-auto" />
                  {item.badge && (
                    <div
                      className={clsx(
                        `rounded bg-${item.badge.color} px-1 py-1px`,
                        'sidenavHoverShow',
                        classes.itemIcon,
                      )}
                    >
                      {item.badge.value}
                    </div>
                  )}
                </TouchRipple>
              </NavLink>
            );
          } else {
            return (
              <NavLink
                key={index}
                to={item.path}
                activeClassName={classes.navItemActive}
                className={clsx({
                  'flex justify-between h-44 border-radius-4 mb-2 compactNavItem whitespace-pre overflow-hidden': true,
                  [classes.navItem]: true,
                  [classes.compactNavItem]: mode === 'compact',
                })}
                onClick={() =>
                  updateSettings &&
                  updateSettings({
                    panelCounter: (settings.panelCounter || 0) + 1,
                  })
                }
              >
                <TouchRipple key={item.name} name="child" className="w-full">
                  {item?.icon ? (
                    <Icon key={item.name} className="material-icons-outlined text-18 align-middle w-36 px-4">
                      {item.icon}
                    </Icon>
                  ) : (
                    <Fragment>
                      <div
                        className={clsx({
                          'nav-bullet p-2px rounded ml-6 mr-2': true,
                          [classes.bulletIcon]: true,
                          hidden: mode === 'compact',
                        })}
                      />
                      <div
                        className={clsx({
                          'nav-bullet-text ml-5 text-11': true,
                          hidden: mode !== 'compact',
                        })}
                      >
                        {item.iconText}
                      </div>
                    </Fragment>
                  )}
                  <span className={clsx('align-middle text-left sidenavHoverShow', classes.itemText)}>{item.name}</span>
                  <div className="mx-auto" />
                  {item.badge && (
                    <div
                      className={clsx(
                        `rounded bg-${item.badge.color} px-1 py-1px`,
                        'sidenavHoverShow',
                        classes.itemIcon,
                      )}
                    >
                      {item.badge.value}
                    </div>
                  )}
                </TouchRipple>
              </NavLink>
            );
          }
        }
      })
    );
  };

  return <div className="navigation">{renderLevels(items)}</div>;
};

export default VerticalNav;
