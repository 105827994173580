import { MerchantPath, RouteConfigWrapper } from '@fabric/ui/src/commons';
import DelegateAccessIframe from '../DelegateAccess/iframe';

const merchantsRoutes: RouteConfigWrapper[] = [
  {
    route: {
      path: MerchantPath,
      exact: true,
      component: () => <DelegateAccessIframe title="Merchants" component="merchants" />,
    },
  },
];

export default merchantsRoutes;
