/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { metabase_dashboard_rpt_id } from 'variables';
import { Skeleton } from '@material-ui/lab';
import { enqueueSnackbar } from '../../redux/notifier/notifier.slice';
import { useSettings } from '@fabric/ui';
import { useGetMetabaseToken } from '../../queries/metabase/metabase.mutation';
import { getErrorMessage } from '@fabric/ui/src/utils';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'app/redux/store';
import { Auth } from 'aws-amplify';
import { authService } from '../../services/authService';
import { setTenantDelegateTokens } from '../../redux/authentication/authentication.slice';

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    padding: '36px 40px 0px 35px',
  },
  list: {
    paddingTop: '24px',
  },
}));

const Index: React.FunctionComponent = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { settings } = useSettings();
  const { tenantDelegateTokens } = useSelector((state: RootState) => state && state.authentication);
  const dashboardId = metabase_dashboard_rpt_id ? parseInt(metabase_dashboard_rpt_id, 10) : 0;

  const tenantDelegateToken = tenantDelegateTokens?.[settings.seletedTenantId]?.idToken;
  const [iframeUrl, setIframeUrl] = useState<string | undefined>('');
  const [queryParams, setQueryParams] = useState({
    dashboard_id: dashboardId,
    account_id: settings.seletedTenantId,
    delegate_Token: tenantDelegateToken,
  });

  const { isLoading, error, data, isError, refetch } = useGetMetabaseToken(queryParams);
  if (isError) {
    const key = new Date().getTime() + Math.random();
    dispatch(
      enqueueSnackbar({
        notification: {
          key,
          message: getErrorMessage(error),
          options: {
            key: key,
            variant: 'error',
          },
        },
      }),
    );
  }

  useEffect(() => {
    if (tenantDelegateToken) {
      setQueryParams({
        dashboard_id: dashboardId,
        account_id: settings.seletedTenantId,
        delegate_Token: tenantDelegateToken,
      });
    }
  }, [tenantDelegateToken, dashboardId, settings.seletedTenantId]);

  useEffect(() => {
    refetch();
  }, [queryParams]);

  useEffect(() => {
    setIframeUrl(data?.data?.url);
  }, [data]);

  useEffect(() => {
    Auth.currentSession().then(async () => {
      if (
        settings.seletedTenantId &&
        settings.seletedTenantId.length > 0 &&
        settings.seletedTenantSlug &&
        settings.seletedTenantSlug.length > 0
      ) {
        const tenantDelegateToken = tenantDelegateTokens?.[settings.seletedTenantId]?.idToken;

        const isValidToken = authService.isValidToken(tenantDelegateToken);

        if (!isValidToken) {
          const tokens = await authService.generateTenantDelegateTokens();
          dispatch(
            setTenantDelegateTokens({
              tenantId: settings.seletedTenantId,
              accessToken: tokens?.AccessToken,
              idToken: tokens?.IdToken,
            }),
          );
        }
      }
    });
  }, [settings]);

  return (
    <div className={classes.root}>
      {isLoading || !iframeUrl ? (
        <Skeleton height={80} />
      ) : (
        <Grid container spacing={3}>
          <Grid item xs={12} className={classes.list}>
            <iframe title="ABC" src={iframeUrl} frameBorder={0} width="100%" height="900" />
          </Grid>
        </Grid>
      )}
    </div>
  );
};

export default Index;
