/* eslint-disable @typescript-eslint/no-explicit-any */
import { call, put, takeLatest } from 'redux-saga/effects';
import * as actions from 'app/redux/tenant/tenant.actions';
import * as tenantSvc from 'app/services/tenantService';
import { getTenantDetailsSuccess, getTenantsMenuSuccess, getTenantsSuccess } from './tenant.slice';
import { requestFinished, requestStarted } from 'app/redux/ui/ui.slice';
import {
  getTenantsActionName,
  getTenantsMenuActionName,
  getTenantDetailsActionName,
  getTenantsAction,
  getTenantsMenuAction,
} from 'app/redux/tenant/tenant.actions';
import { enqueueSnackbar } from 'app/redux/notifier/notifier.slice';
import { enqueueErrorAction, enqueueSuccessAction } from 'app/redux/notifier/notifier.actions';
import { getErrorMessage } from '@fabric/ui/src/utils';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function* sagas() {
  yield takeLatest(actions.getTenantsAction, getTenantsHandler);
  yield takeLatest(actions.getTenantsMenuAction, getTenantsMenuHandler);
  yield takeLatest(actions.createTenantAction, createTenantHandler);
  yield takeLatest(actions.editTenantAction, editTenantHandler);
  yield takeLatest(actions.deleteTenantAction, deleteTenantHandler);
  yield takeLatest(actions.getTenantDetailsAction, getTenantDetailsHandler);
}

function* getTenantsHandler({ payload }: ReturnType<typeof actions.getTenantsAction>) {
  try {
    const { page_size, page, criterias, sorter } = payload;
    yield put(requestStarted({ name: getTenantsActionName }));
    const {
      data: { data, metadata },
    } = yield call(tenantSvc.getTenants, { page_size, page }, criterias, sorter);
    yield put(getTenantsSuccess({ data, metadata }));
    yield put(requestFinished({ name: getTenantsActionName }));
  } catch (error) {
    const key = new Date().getTime() + Math.random();
    yield put(
      enqueueSnackbar({
        notification: {
          key,
          message: getErrorMessage(error),
          options: {
            key: key,
            variant: 'error',
          },
        },
      }),
    );
  }
}

function* getTenantsMenuHandler({ payload }: ReturnType<typeof actions.getTenantsMenuAction>) {
  try {
    const { page_size, page, criterias } = payload;
    yield put(requestStarted({ name: getTenantsMenuActionName }));
    const {
      data: { data },
    } = yield call(tenantSvc.getTenants, { page_size, page }, criterias);
    yield put(getTenantsMenuSuccess({ data, criterias }));
    yield put(requestFinished({ name: getTenantsMenuActionName }));
  } catch (error) {
    const key = new Date().getTime() + Math.random();
    yield put(
      enqueueSnackbar({
        notification: {
          key,
          message: getErrorMessage(error),
          options: {
            key: key,
            variant: 'error',
          },
        },
      }),
    );
  }
}

function* createTenantHandler({ payload }: ReturnType<typeof actions.createTenantAction>) {
  try {
    const { tenant, history } = payload;
    const { data } = yield call(tenantSvc.createTenant, tenant);

    if (data && data.id) {
      history.push(`/tenant`);
    }

    yield put(enqueueSuccessAction({ msg: 'Tenant Created' }));
  } catch (error) {
    yield put(enqueueErrorAction({ msg: getErrorMessage(error) }));
  }
}

function* editTenantHandler({ payload }: ReturnType<typeof actions.editTenantAction>) {
  try {
    const { tenant, history } = payload;
    const { data } = yield call(tenantSvc.editTenant, tenant);

    if (data && data.id) {
      history.push(`/tenant`);
    }
    yield put(enqueueSuccessAction({ msg: 'Tenant Updated' }));
  } catch (error) {
    yield put(enqueueErrorAction({ msg: getErrorMessage(error) }));
  }
}

function* getTenantDetailsHandler({ payload }: ReturnType<typeof actions.getTenantDetailsAction>) {
  try {
    const { id } = payload;
    yield put(requestStarted({ name: getTenantDetailsActionName }));
    const { data } = yield call(tenantSvc.getTenantDetails, id);
    yield put(getTenantDetailsSuccess({ data }));
    yield put(requestFinished({ name: getTenantDetailsActionName }));
  } catch (error) {
    const key = new Date().getTime() + Math.random();
    yield put(
      enqueueSnackbar({
        notification: {
          key,
          message: getErrorMessage(error),
          options: {
            key: key,
            variant: 'error',
          },
        },
      }),
    );
  }
}

function* deleteTenantHandler({ payload }: ReturnType<typeof actions.deleteTenantAction>) {
  try {
    const { tenant, page_size, page, criterias, sorter } = payload;
    const {
      data: { message },
    } = yield call(tenantSvc.deleteTenant, tenant);

    if (message && message.toLowerCase() === 'ok') {
      yield put(enqueueSuccessAction({ msg: 'Tenant Deleted' }));
      yield put(getTenantsAction({ page_size, page, criterias, sorter }));
      yield put(
        getTenantsMenuAction({
          page: 1,
          page_size: 20,
          criterias: [
            {
              field: 'status',
              value: 'active',
            },
          ],
        }),
      );
    } else {
      yield put(enqueueErrorAction({ msg: message }));
    }
  } catch (error) {
    yield put(enqueueErrorAction({ msg: getErrorMessage(error) }));
  }
}

export default sagas;
