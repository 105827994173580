/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Request } from 'app/models/request';
import { filter } from 'lodash';
export interface UIState {
  requests: Request[];
}

const initialState: UIState = {
  requests: [],
};

const ui = createSlice({
  name: `ui`,
  initialState,
  reducers: {
    resetState: (state) => {
      state.requests = [];
    },
    requestStarted: (state, action: PayloadAction<{ name: string }>) => {
      const { name } = action.payload;
      state.requests = [
        ...state.requests,
        {
          name,
          loading: true,
        },
      ];
    },
    requestFinished: (state, action: PayloadAction<{ name: string }>) => {
      const { name } = action.payload;
      state.requests = filter(state.requests, (r: Request) => r.name !== name);
    },
  },
});

export const { resetState, requestStarted, requestFinished } = ui.actions;

export default ui.reducer;
