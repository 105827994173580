import React, { useEffect, useRef } from 'react';
import { useSettings } from '@fabric/ui';
import { tenant_base_url } from '../../../variables';
import { Auth } from 'aws-amplify';
import { buildIframeUri } from '@fabric/ui/src/components/DataGrid/filterColumn';
import { authService } from '../../services/authService';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'app/redux/store';
import { setTenantDelegateTokens } from '../../redux/authentication/authentication.slice';

interface DelegateAccessIframeProps {
  component: string;
  title: string;
  params?: Record<string, any>;
}

const DelegateAccessIframe: React.FC<DelegateAccessIframeProps> = ({
  title,
  component,
  params = {},
}: DelegateAccessIframeProps) => {
  const { settings } = useSettings();
  const dispatch = useDispatch();
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const iframe = iframeRef.current;

  const [url, setUrl] = React.useState<string>('');
  const [token, setToken] = React.useState<string>('');

  const { tenantDelegateTokens } = useSelector((state: RootState) => state && state.authentication);

  const sendMessageToIframe = (iframe: Window, messageData: Record<string, string | undefined>) => {
    iframe.postMessage(messageData, '*');
  };

  useEffect(() => {
    Auth.currentSession().then(async () => {
      if (
        settings.seletedTenantId &&
        settings.seletedTenantId.length > 0 &&
        settings.seletedTenantSlug &&
        settings.seletedTenantSlug.length > 0
      ) {
        let tenantDelegateToken = tenantDelegateTokens?.[settings.seletedTenantId]?.idToken;

        const isValidToken = authService.isValidToken(tenantDelegateToken);

        if (!isValidToken) {
          const tokens = await authService.generateTenantDelegateTokens();
          dispatch(
            setTenantDelegateTokens({
              tenantId: settings.seletedTenantId,
              accessToken: tokens?.AccessToken,
              idToken: tokens?.IdToken,
            }),
          );
          tenantDelegateToken = tokens?.IdToken;
        }

        setToken(tenantDelegateToken || '');

        const iframeUrl = buildIframeUri(component, params, tenant_base_url, settings.seletedTenantSlug);
        setUrl(iframeUrl);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [component, settings]);

  useEffect(() => {
    iframe?.addEventListener('load', () => {
      const iframeContentWindow = iframe.contentWindow;
      if (iframeContentWindow && token && settings.seletedTenantId?.length) {
        sendMessageToIframe(iframeContentWindow, { token, accountId: settings.seletedTenantId });
      }
    });
  }, [iframe, token, settings.seletedTenantId]);

  return (
    <iframe
      ref={iframeRef}
      title={title}
      style={{ border: 'none' }}
      width="100%"
      height="99%"
      src={url}
      allow="clipboard-read; clipboard-write"
    />
  );
};

export default DelegateAccessIframe;
