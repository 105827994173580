/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Grid, Box } from '@material-ui/core';
import clsx from 'clsx';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import { useTxnDetailStyles } from './styles';
import { Alert, Skeleton } from '@material-ui/lab';
import { times } from 'lodash';
import { useHistory } from 'react-router-dom';

interface OwnProps {
  title: string;
  isLoading?: boolean;
  isError?: boolean;
  data?: { title: string; value?: string; isCopyValue?: boolean }[];
  redirectLink?: string;
}

type Props = OwnProps;

const DetailCard: React.FunctionComponent<Props> = ({ title, isLoading, isError, data, redirectLink }) => {
  const classes = useTxnDetailStyles();
  const history = useHistory();

  const handleRedirect = () => {
    if (redirectLink) {
      history.push(redirectLink);
    }
  };

  return (
    <Box className={classes.section} justifyContent="center">
      <Accordion defaultExpanded>
        <AccordionSummary
          className={clsx(classes.sectionSummary)}
          expandIcon={redirectLink ? <KeyboardArrowLeft /> : <ExpandMoreIcon />}
          onClick={handleRedirect}
        >
          {title}
        </AccordionSummary>
        {data && (
          <AccordionDetails>
            {isLoading && (
              <Grid xs={12}>
                {times(data.length).map(() => (
                  <Skeleton />
                ))}
              </Grid>
            )}
            {isError && (
              <Grid xs={12}>
                <Alert severity="error">Not found!</Alert>
              </Grid>
            )}
            {!isError && !isLoading && (
              <Grid container spacing={3}>
                {data.map((item, index) => (
                  <Grid container key={index}>
                    <Grid item xs={6} className={clsx(classes.label)}>
                      {item.title}:
                    </Grid>
                    {item.isCopyValue ? (
                      <Grid item xs={6} className={clsx(classes.alignRight)}>
                        {item.value?.substring(0, 10)}.....
                        <a
                          href={'#'}
                          style={{ textDecoration: 'underline' }}
                          onClick={() => item.value && navigator.clipboard.writeText(item.value)}
                        >
                          copy
                        </a>
                      </Grid>
                    ) : (
                      <Grid item xs={6} className={clsx(classes.alignRight)}>
                        {item.value}
                      </Grid>
                    )}
                  </Grid>
                ))}
              </Grid>
            )}
          </AccordionDetails>
        )}
      </Accordion>
    </Box>
  );
};

export default DetailCard;
