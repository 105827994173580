import { makeStyles, ThemeOptions } from '@material-ui/core/styles';

export const shadowStyles = makeStyles(({ ...theme }) => ({
  '@global': {
    ...generateShadows(theme),
  },
}));

const generateShadows = (theme: ThemeOptions) => {
  const classList = {};
  theme.shadows?.forEach((shadow, ind) => {
    // @ts-ignore
    classList[`.elevation-z${ind}`] = {
      boxShadow: `${shadow} !important`,
    };
  });

  return classList;
};
