import * as React from 'react';
import { useEffect, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem, TextField } from '@material-ui/core';
import clsx from 'clsx';
import { useFormik } from 'formik';
import DataGridFilterColumn, {
  DataGridFilterType,
  mapValueForFilterColumn,
  resetValueForFilterColumn,
  transformFilterColumnToObject,
  transformFilterColumnToValidation,
} from './filterColumn';
import { FieldContainer } from '../index';
import { filter } from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import FilterIcon from '@material-ui/icons/FilterListOutlined';
import { DateRangeInput } from './DateRangeInput';
import { DropdownSelectionFilter } from './DropDownSelection';
import { TextInputMultiple } from './TextInputMultiple';
import { DateInput } from './DateInput';

interface DataGridFilterProps {
  className?: string;
  onFilter?: (values: DataGridFilterColumn[]) => void;
  onClose?: () => void;
  variant?: 'text' | 'outlined' | 'contained' | undefined;
  criterias: DataGridFilterColumn[];
  isUniqueFilter?: boolean;
  subtitle?: string;
}

const useStyles = makeStyles(() => ({
  fieldContainer: {
    padding: '0px 10px 20px 10px',
  },
  dialogContent: {
    paddingBottom: '30px',
  },
  subtitle: {
    fontSize: '0.75rem',
    padding: '5px 24px',
    color: 'gray',
  },
}));

const DataGridFilter: React.FC<DataGridFilterProps> = ({
  className,
  criterias,
  onFilter,
  variant,
  isUniqueFilter,
  subtitle,
}: DataGridFilterProps) => {
  const [openModal, setOpenModal] = useState(false);
  const [filterCount, setFilterCount] = useState(0);

  useEffect(() => {
    const v = filter(criterias, (c) => c.value);
    setFilterCount(v.length);
  }, [criterias]);

  const onClick = () => {
    setOpenModal(true);
  };

  const handleFilter = (values: DataGridFilterColumn[]) => {
    onFilter && onFilter(values);
    const v = filter(values, (c) => c.value);
    setFilterCount(v.length);
    setOpenModal(false);
  };

  const handleClose = () => {
    setOpenModal(false);
  };
  return (
    <div>
      <Button variant={variant} type="button" onClick={onClick} className={clsx(className)} startIcon={<FilterIcon />}>
        Filters {filterCount > 0 ? `(${filterCount})` : ''}
      </Button>
      <DataGridFilterModal
        isOpen={openModal}
        isUniqueFilter={isUniqueFilter}
        criterias={criterias}
        onFilter={handleFilter}
        onClose={handleClose}
        subtitle={subtitle}
      />
    </div>
  );
};

interface DataGridFilterModalProps {
  isOpen: boolean;
  isUniqueFilter?: boolean;
  criterias: DataGridFilterColumn[];
  onFilter?: (values: DataGridFilterColumn[]) => void;
  onClose?: () => void;
  subtitle?: string;
}

const DataGridFilterModal: React.FC<DataGridFilterModalProps> = ({
  isOpen,
  onFilter,
  onClose,
  criterias,
  isUniqueFilter,
  subtitle,
}: DataGridFilterModalProps) => {
  const classes = useStyles();

  const [currentSelection, setCurrentSelection] = useState<string>();

  const setValueFromFilterColumn = (filterColumnValues: DataGridFilterColumn[]) => {
    const filterColumnObject = transformFilterColumnToObject(filterColumnValues);
    for (const k in filterColumnObject) {
      setFieldValue(k, filterColumnObject[k]);
    }
  };

  useEffect(() => {
    setValueFromFilterColumn(criterias);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [criterias]);

  const submitForm = () => {
    const resetValues = resetValueForFilterColumn(criterias);
    const mapValues = mapValueForFilterColumn(resetValues, values);

    onFilter && onFilter(mapValues);
  };

  const onReset = () => {
    const resetValues = resetValueForFilterColumn(criterias);
    setValueFromFilterColumn(resetValues);
    onFilter && onFilter(resetValues);
  };

  const handleClose = () => {
    onClose && onClose();
  };

  const formik = useFormik({
    initialValues: transformFilterColumnToObject(criterias),
    validationSchema: transformFilterColumnToValidation(criterias),
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: submitForm,
  });

  const { values, setFieldValue, handleSubmit, touched, errors } = formik;

  return (
    <Dialog
      fullWidth={true}
      maxWidth="xs"
      open={isOpen}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      disableEnforceFocus
      onClose={handleClose}
    >
      <form onSubmit={handleSubmit}>
        <DialogTitle id="confirmation-dialog-title" style={{ padding: '16px 24px 5px 24px' }}>
          Filter
        </DialogTitle>
        {subtitle && <div className={clsx(classes.subtitle)}>{subtitle}</div>}
        <DialogContent dividers>
          {criterias.map((col: DataGridFilterColumn, i: number) => {
            return (
              <FieldContainer key={i} className={clsx(classes.fieldContainer)}>
                {(!col.type || col.type === DataGridFilterType.TEXT_INPUT) && (
                  <TextField
                    fullWidth
                    id={col.field}
                    name={col.field}
                    label={col.name}
                    value={values[col.field]}
                    onBlur={formik.handleBlur}
                    onChange={(...params) => {
                      if (isUniqueFilter) {
                        setCurrentSelection(col.field);
                      }
                      formik.handleChange(...params);
                    }}
                    disabled={
                      isUniqueFilter &&
                      (currentSelection ? !!values[currentSelection] : false) &&
                      col.field !== currentSelection
                    }
                    error={touched[col.field] && Boolean(errors[col.field])}
                    helperText={touched[col.field] && errors[col.field]}
                  />
                )}
                {col.type === DataGridFilterType.TEXT_INPUT_MULTIPLE && (
                  <TextInputMultiple
                    col={col}
                    values={values}
                    formik={formik}
                    isUniqueFilter={isUniqueFilter}
                    setCurrentSelection={setCurrentSelection}
                  />
                )}
                {col.type === DataGridFilterType.DATE_PICKER && (
                  <DateInput
                    name={col.name}
                    value={values[col.field]}
                    onChange={(...params) => {
                      formik.setFieldValue(col.field, ...params);
                    }}
                    disabled={
                      isUniqueFilter &&
                      (currentSelection ? !values[currentSelection] : false) &&
                      col.field !== currentSelection
                    }
                  />
                )}
                {col.type === DataGridFilterType.DATE_RANGE_PICKER && (
                  <DateRangeInput
                    name={col.name}
                    value={values[col.field]}
                    onChange={(...params) => {
                      formik.setFieldValue(col.field, ...params);
                    }}
                    disabled={
                      isUniqueFilter &&
                      (currentSelection ? !values[currentSelection] : false) &&
                      col.field !== currentSelection
                    }
                  />
                )}
                {col.type === DataGridFilterType.DROPDOWN && (
                  <TextField
                    fullWidth
                    select
                    id={col.field}
                    name={col.field}
                    label={col.name}
                    value={values[col.field]}
                    onBlur={formik.handleBlur}
                    onChange={(...params) => {
                      if (isUniqueFilter) {
                        setCurrentSelection(col.field);
                      }
                      formik.handleChange(...params);
                    }}
                    disabled={
                      isUniqueFilter &&
                      (currentSelection ? !values[currentSelection] : false) &&
                      col.field !== currentSelection
                    }
                    error={touched[col.field] && Boolean(errors[col.field])}
                    helperText={touched[col.field] && errors[col.field]}
                  >
                    {col.values && col.values.map((v) => <MenuItem value={v.value}>{v.label}</MenuItem>)}
                  </TextField>
                )}
                {col.type === DataGridFilterType.DROPDOWN_SELECTION && (
                  <DropdownSelectionFilter
                    col={col}
                    values={values}
                    formik={formik}
                    isUniqueFilter={isUniqueFilter}
                    setCurrentSelection={setCurrentSelection}
                  />
                )}
              </FieldContainer>
            );
          })}
        </DialogContent>
        <DialogActions>
          <Button type="button" color="primary" onClick={onReset}>
            Reset
          </Button>
          <Button type="submit" color="primary">
            Filter
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default DataGridFilter;
