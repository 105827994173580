/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { createContext, useState } from 'react';
import { merge } from 'lodash';
import { LayoutSettings } from '../components/Layout/settings';

export interface SettingContextProps {
  settings: any;
  // eslint-disable-next-line @typescript-eslint/ban-types
  updateSettings?: (newSetting: object) => void;
  overrideSettings?: (newSetting: object) => void;
}
const SettingsContext = createContext<SettingContextProps>({
  settings: LayoutSettings,
  updateSettings: undefined,
  overrideSettings: undefined,
});

interface SettingsProps {
  settings?: object;
  children?: React.ReactNode;
}
export const SettingsProvider: React.FunctionComponent<SettingsProps> = (props: {
  settings?: object;
  children?: React.ReactNode;
}) => {
  const [currentSettings, setCurrentSettings] = useState<object>(props.settings || LayoutSettings);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleUpdateSettings = (update: any = {}) => {
    const merged = merge({}, currentSettings, update);
    setCurrentSettings(merged);
  };

  const handleOverrideSettings = (update: any = {}) => {
    const merged = { ...currentSettings, ...update };
    setCurrentSettings(merged);
  };

  return (
    <SettingsContext.Provider
      value={{
        settings: currentSettings,
        updateSettings: handleUpdateSettings,
        overrideSettings: handleOverrideSettings,
      }}
    >
      {props.children}
    </SettingsContext.Provider>
  );
};

export default SettingsContext;
