import React from 'react';
import { DateTimePicker } from '@material-ui/pickers';
import { Grid, TextField } from '@material-ui/core';
import { getStringDate, isDate } from '../utils/utils';

export interface DateInputProps {
  name?: string;
  value?: string;
  onChange?: (v: string) => void;
  disabled?: boolean;
}

export const DateInput = (props?: DateInputProps) => {
  const [value, setValue] = React.useState<Date | null>(null);

  React.useEffect(() => {
    const date = props?.value;
    if (date) {
      setValue(new Date(date));
    }
  }, [props?.value]);

  const onChange = (newValue: Date | null) => {
    const isValidDate = !newValue || isDate(newValue);

    if (props?.onChange && isValidDate) {
      props.onChange(`${getStringDate(newValue)}`);
    }

    setValue(newValue);
  };

  return (
    <Grid container direction="column">
      <Grid container direction="row" spacing={2}>
        <Grid item xs={6}>
          <DateTimePicker
            renderInput={(pickerProps) => <TextField {...pickerProps} helperText={''} />}
            label={props?.name || ''}
            ampm={false}
            value={value}
            onChange={(newValue) => onChange(newValue)}
            disabled={props?.disabled}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
