/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosResponse } from 'axios';
import { apiClient } from 'app/commons/axios';
import { Tenant, TenantListing } from '../models/tenant';
import DataGridFilterColumn from '@fabric/ui/src/components/DataGrid/filterColumn';
import { Paging } from 'app/models/paging';
import { SorterResult } from 'antd/lib/table/interface';
import { buildSortUrl, urlFromSearchCriteria } from '@fabric/ui';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getTenants = async (
  { page_size, page }: Paging,
  criterias?: DataGridFilterColumn[],
  sorter?: SorterResult<Tenant>[],
): Promise<AxiosResponse<TenantListing>> => {
  let url = `/v1/tenants?page=${page}&page_size=${page_size}`;
  url = urlFromSearchCriteria(url, criterias);
  url = buildSortUrl(url, sorter);
  return apiClient.get(url);
};

export const getTenantList = async (
  paging: Paging,
  criterias?: DataGridFilterColumn[],
  sorter?: SorterResult<Tenant>[],
): Promise<TenantListing> => {
  const res = await getTenants(paging, criterias, sorter);
  return res?.data;
};

export const getTenantDetails = (id: string): Promise<AxiosResponse<unknown>> => {
  const url = `/v1/tenants/${id}`;
  return apiClient.get(url);
};

export const createTenant = (tenant: Tenant): Promise<Tenant> => {
  return apiClient.post('/v1/tenants', tenant);
};

export const editTenant = (tenant: Tenant): Promise<Tenant> => {
  return apiClient.put(`/v1/tenants/${tenant.id}`, tenant);
};

export const deleteTenant = (tenant: Tenant): Promise<Tenant> => {
  return apiClient.delete(`/v1/tenants/${tenant.id}`);
};
