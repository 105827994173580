/* eslint-disable @typescript-eslint/no-explicit-any */
import { combineReducers } from '@reduxjs/toolkit';
import authReducer from './authentication/authentication.slice';
import tenant from './tenant/tenant.slice';
import notifier from 'app/redux/notifier/notifier.slice';
import ui from 'app/redux/ui/ui.slice';
import initReducer from './init/init.slice';
import entityReducer from './entity/entity.slice';

const appReducer = combineReducers({
  init: initReducer,
  authentication: authReducer,
  tenant: tenant,
  ui: ui,
  notifier: notifier,
  entity: entityReducer,
});

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const rootReducer = (state: any, action: any) => {
  // when a logout action is dispatched it will reset redux state
  if (action.type === 'LOGOUT_SUCCESS') {
    state = { init: { isStarted: true } };
  }

  return appReducer(state, action);
};

export default rootReducer;
