/* eslint-disable @typescript-eslint/no-explicit-any */
import { put, takeLatest } from 'redux-saga/effects';
import * as actions from 'app/redux/ui/ui.actions';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function* sagas() {
  yield takeLatest(actions.requestStartedAction, requestStartedHandler);
  yield takeLatest(actions.requestFinishedAction, requestFinishedHandler);
}

function* requestStartedHandler({ payload }: ReturnType<typeof actions.requestStartedAction>) {
  const { name } = payload;
  yield put(actions.requestStarted({ name }));
}

function* requestFinishedHandler({ payload }: ReturnType<typeof actions.requestFinishedAction>) {
  const { name } = payload;
  yield put(actions.requestFinished({ name }));
}

export default sagas;
