import { CustomerPath, RouteConfigWrapper } from '@fabric/ui/src/commons';
import DelegateAccessIframe from '../DelegateAccess/iframe';

const customersRoutes: RouteConfigWrapper[] = [
  {
    route: {
      path: CustomerPath,
      exact: true,
      component: () => <DelegateAccessIframe title="Customers" component="customers" />,
    },
  },
];

export default customersRoutes;
