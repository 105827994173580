import React from 'react';
import { useDispatch } from 'react-redux';
import { getInit } from './app/redux/init/init.slice';

const Init: React.FunctionComponent = () => {
  const dispatch = useDispatch();
  dispatch(getInit());

  return <div></div>;
};

export default Init;
