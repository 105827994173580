import EntityList from './entity.list';
import EntityCreate from './entity.create';
import { RouteConfigWrapper } from '@fabric/ui/src/commons';

const entityRoutes: RouteConfigWrapper[] = [
  {
    route: { path: '/entity', exact: true, component: EntityList },
  },
  {
    route: { path: '/entity/new', exact: true, component: EntityCreate },
  },
  {
    route: { path: '/entity/edit/:id', exact: true, component: EntityCreate },
  },
];

export default entityRoutes;
