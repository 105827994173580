import React, { useState } from 'react';
import { CardMenu } from '@fabric/ui';
import { Typography } from '@material-ui/core/';
import { isArray } from 'lodash';
import { useTranslation } from 'react-i18next';
import { Table } from 'antd';
import { useAuditLogListing } from '../../queries/audit/audit.query';
import { Paging } from '../../models/paging';
import { getFooterOfPagination, toLocalClientTimestamp } from '../../../utils';
import { SorterResult, TablePaginationConfig } from 'antd/lib/table/interface';
import { useDispatch } from 'react-redux';
import { enqueueSnackbar } from '../../redux/notifier/notifier.slice';
import DataGridFilterColumn, {
  DataGridFilterType,
  mapValueForFilterColumn,
  redirectByFilter,
} from '@fabric/ui/src/components/DataGrid/filterColumn';
import DataGridFilter from '@fabric/ui/src/components/DataGrid/filter';
import { AuditLogV2, AuditLogV2Metadata } from '../../models/audit';
import { useQueryParams } from '../../hooks/useQueryParams';
import { useHistory } from 'react-router-dom';
import { getErrorMessage } from '@fabric/ui/src/utils';
import { makeStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import AuditDetailsModal from './audit.details';
import { auditAction, auditActorType, auditResourceType } from './helpers';

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    padding: '36px 40px 0px 35px',
  },
  list: {
    paddingTop: '24px',
  },
  entityAddSection: {
    display: 'flex',
    gap: '5px',
    marginBottom: '10px',
  },
  modal: {
    top: 0,
    right: 0,
    position: 'fixed',
    width: '640px',
    height: '100%',
    background: '#FFFFFF',
  },
}));

const columns = [
  {
    title: 'Audit Id',
    dataIndex: 'id',
    sorter: false,
  },
  {
    title: 'Action',
    dataIndex: 'metadata',
    sorter: false,
    render: (metadata: AuditLogV2Metadata) => auditAction(metadata),
  },
  {
    title: 'Action Id',
    dataIndex: 'trigger_uid',
    sorter: false,
  },
  {
    title: 'Session Id',
    dataIndex: 'session_uid',
    sorter: false,
  },
  {
    title: 'Actor Type',
    dataIndex: 'actor_type',
    sorter: false,
    render: (actorType: string) => auditActorType(actorType),
  },
  {
    title: 'Actor Id',
    dataIndex: 'actor_uid',
    sorter: false,
  },
  {
    title: 'Tenant Id',
    dataIndex: 'tenant_id',
    sorter: false,
  },
  {
    title: 'Resource Type',
    dataIndex: 'resource_type',
    sorter: false,
    render: (resourceType: string) => auditResourceType(resourceType),
  },
  {
    title: 'Resource Id',
    dataIndex: 'resource_id',
    sorter: false,
  },
  {
    title: 'IP Address',
    dataIndex: 'metadata',
    sorter: false,
    render: (metadata: AuditLogV2Metadata) => metadata.ip_address,
  },
  {
    title: 'Time Stamp (Local)',
    dataIndex: 'created_at',
    sorter: {
      multiple: 1,
    },
    render: (param: string) => toLocalClientTimestamp(param),
  },
];

const filterColumns: DataGridFilterColumn[] = [
  {
    field: 'resource_id',
    name: 'Resource',
  },
  {
    field: 'actor',
    name: 'Actor Email/Client ID',
  },
  {
    field: 'created_at__gt',
    type: DataGridFilterType.DATE_PICKER,
    name: 'From Date',
  },
  {
    field: 'created_at__lt',
    type: DataGridFilterType.DATE_PICKER,
    name: 'To Date',
  },
];

type FilterParams = {
  actor?: string;
  resource_id?: string;
  created_at__gt?: string;
  created_at__lt?: string;
};

const AuditLog: React.FunctionComponent = () => {
  const params = useQueryParams<FilterParams>();
  const history = useHistory();
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [pagination, setPagination] = useState<Paging>({
    page: 1,
    page_size: 10,
  });
  const criterias = mapValueForFilterColumn(filterColumns, params);
  const [sort, setSort] = useState<SorterResult<AuditLogV2>[]>([]);
  const pathname = window.location.pathname;
  const [auditLogDetailModel, setAuditLogDetailModel] = useState({
    status: false,
    auditLog: {} as any,
  });

  const {
    data: auditLogsData,
    isLoading: isAuditLogsLoading,
    isFetching: isAuditLogsFetching,
    isError: isAuditLogsError,
    error: auditLogsError,
  } = useAuditLogListing(pagination, criterias, sort);

  if (isAuditLogsError) {
    const key = new Date().getTime() + Math.random();
    dispatch(
      enqueueSnackbar({
        notification: {
          key,
          message: getErrorMessage(auditLogsError),
          options: {
            key: key,
            variant: 'error',
          },
        },
      }),
    );
  }

  // @ts-ignore
  const onChange = (
    changedPagination: TablePaginationConfig,
    _filters: any,
    sorter: SorterResult<AuditLogV2> | SorterResult<AuditLogV2>[],
    _extra: any,
  ) => {
    if (
      changedPagination.current &&
      changedPagination.pageSize &&
      (changedPagination.current !== pagination.page || changedPagination.pageSize !== pagination.page_size)
    ) {
      setPagination({
        page: changedPagination.current,
        page_size: changedPagination.pageSize,
      });
    }

    if (!sorter) {
      setSort([]);
    } else if (!isArray(sorter)) {
      setSort([sorter]);
    } else {
      setSort(sorter);
    }
  };

  const onRowClicked = (auditLog: AuditLogV2, _rowIndex: any, _event: any) => {
    setAuditLogDetailModel({
      status: true,
      auditLog: auditLog,
    });
  };

  const closeAuditLogDetailModel = () => {
    setAuditLogDetailModel({
      status: false,
      auditLog: {},
    });
  };

  return (
    <CardMenu
      subtitle={t('audit.auditTrailDescription')}
      actions={[
        <DataGridFilter
          key={1}
          variant="outlined"
          onFilter={(values: DataGridFilterColumn[]) => redirectByFilter(values, history, pathname)}
          criterias={criterias}
        />,
      ]}
    >
      <Table
        rowKey={'id'}
        loading={isAuditLogsLoading || isAuditLogsFetching}
        columns={columns}
        dataSource={auditLogsData?.data?.data}
        scroll={{ x: 1520, y: 700 }}
        pagination={{
          current: auditLogsData?.data?.metadata?.page,
          total: auditLogsData?.data?.metadata?.total,
          pageSize: auditLogsData?.data?.metadata?.page_size,
        }}
        footer={() => <Typography align={'right'}>{getFooterOfPagination(auditLogsData?.data?.metadata)}</Typography>}
        onChange={onChange}
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => {
              onRowClicked(record, rowIndex, event);
            }, // click row
          };
        }}
        size="middle"
      />
      {auditLogDetailModel.status && (
        <AuditDetailsModal
          isOpen={auditLogDetailModel.status}
          auditLog={auditLogDetailModel.auditLog}
          onClose={closeAuditLogDetailModel}
          className={classes.modal}
        />
      )}
    </CardMenu>
  );
};

export default AuditLog;
