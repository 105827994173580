import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { Box, Button as CoreButton } from '@material-ui/core/';
import { CSSProperties } from '@material-ui/core/styles/withStyles';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const useStyles = makeStyles((props: { palette?: any }) => ({
  root: {
    color: props.palette?.text.white,
    backgroundColor: props.palette?.primary.main,
    '&:hover': {
      backgroundColor: '#e1dfdf',
      color: '#000',
    },
    width: '160px',
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '13px',
    lineHeight: '19px',
    textAlign: 'center',
    letterSpacing: '0.01em',
  },
}));

const Button: React.FunctionComponent<{
  variant?: 'text' | 'outlined' | 'contained' | undefined;
  type: 'submit' | 'button';
  style?: CSSProperties;
  children?: React.ReactNode;
  className?: string;
  tabIndex?: number;
  disabled?: boolean;
  onClick?: () => void;
}> = (props) => {
  const classes = useStyles();

  return (
    <Box>
      <CoreButton
        className={clsx(classes.root, props.className)}
        style={props.style}
        variant={props.variant}
        type={props.type}
        onClick={props.onClick}
        tabIndex={props.tabIndex}
        disabled={props.disabled}
      >
        {props.children}
      </CoreButton>
    </Box>
  );
};

export default Button;
