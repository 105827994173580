import { isEmpty } from 'lodash';
import { History } from 'history';
import { DELEGATE_ACCESS_IFRAME_URL, DelegateAccessPathname } from '../../commons';
import * as yup from 'yup';
import { extractPath } from '../utils/utils';

export enum DataGridFilterType {
  TEXT_INPUT = 'TEXT_INPUT',
  TEXT_INPUT_MULTIPLE = 'TEXT_INPUT_MULTIPLE',
  DATE_PICKER = 'DATE_PICKER',
  DATE_RANGE_PICKER = 'DATE_RANGE_PICKER',
  DROPDOWN_SELECTION = 'DROPDOWN_SELECTION',
  DROPDOWN = 'DROPDOWN',
}

export interface DataGridFilterSelectOption {
  value: string;
  label: string;
}

interface DataGridFilterColumn {
  field: string;
  name?: string;
  type?: DataGridFilterType;
  value?: string | string[];
  values?: DataGridFilterSelectOption[];
  validation?: yup.AnySchema;
  toQueryParams?: (value: any) => string;
}

export function mapValueForFilterColumn(filterColumns: DataGridFilterColumn[], params: any) {
  return filterColumns.map((f) => {
    const value = params[f.field as any];
    if (value) {
      return { ...f, value };
    }
    return f;
  });
}

export function resetValueForFilterColumn(filterColumns: DataGridFilterColumn[]) {
  return filterColumns.map((f) => {
    delete f.value;
    return f;
  });
}

export const transformFilterColumnToObject = (filterColumns: DataGridFilterColumn[]) =>
  filterColumns.reduce<Record<string, any>>((prev, curr) => {
    if (curr.type === DataGridFilterType.DROPDOWN_SELECTION) {
      prev[curr.field] = Array.isArray(curr.value) ? curr.value : curr.value?.replaceAll('|', ',').split(',');
    } else {
      prev[curr.field] = curr.value || '';
    }
    return prev;
  }, {});

export const transformFilterColumnToValidation = (filterColumns: DataGridFilterColumn[]) => {
  const validationObject = filterColumns.reduce<Record<string, any>>((prev, curr) => {
    if (curr.validation) {
      prev[curr.field] = curr.validation;
    }
    return prev;
  }, {});

  return yup.object(validationObject);
};

export const redirectByFilter = (values: DataGridFilterColumn[], history: History, path: string) => {
  if (isEmpty(values)) {
    history.replace(path);
  } else {
    const paramUri = values
      .filter((v) => {
        if (Array.isArray(v.value)) {
          return v.value.length > 0;
        } else {
          return v.value;
        }
      })
      .map((v) => {
        return v.toQueryParams ? v.toQueryParams(v.value) : toDefaultQueryParam(v);
      })
      .join('&');

    const replaceUrl = extractPath(path) === DelegateAccessPathname ? `${path}&${paramUri}` : `${path}?${paramUri}`;
    history.replace(replaceUrl);
  }
};

export const buildParamsUri = (uri: string, params?: Record<string, any>, concatString = '?') => {
  if (isEmpty(params) || !params) {
    return uri;
  }
  const paramUri = Object.keys(params)
    .map((key) => `${key}=${encodeURIComponent(params[key])}`)
    .join('&');

  return `${uri}${concatString}${paramUri}`;
};

export const buildIframeUri = (
  component?: string,
  params: Record<string, any> = {},
  tenantBaseUrl?: string,
  slug?: string,
  template: string = DELEGATE_ACCESS_IFRAME_URL,
) => {
  const iframeUrl = template
    .replace('%BASE_URL%', tenantBaseUrl ? tenantBaseUrl.replace('%TENANT_SLUG%', slug ? slug : '') : '')
    .replace('%COMPONENT%', component ? component : '');
  return buildParamsUri(iframeUrl, params, '&');
};

export const toDefaultQueryParam = (filter: DataGridFilterColumn) => {
  const value = filter.value as string;
  return `${filter.field}=${encodeURIComponent(value || '')}`;
};
export const toGraphQLRegexFilter = (key: string, value: string[] | string) => {
  return Array.isArray(value) ? `${key}=${value.join('|')}` : value.replaceAll(',', '|');
};

export default DataGridFilterColumn;
