import { put, takeLatest } from 'redux-saga/effects';
import { enqueueErrorAction } from '../notifier/notifier.actions';

import { Amplify } from 'aws-amplify';
import { getInit, getInitSuccess } from './init.slice';
import { aws_cognito_region, aws_user_pools_id, aws_user_pools_web_client_id } from 'variables';
import { getErrorMessage } from '@fabric/ui/src/utils';

function* handleStartApp() {
  try {
    Amplify.configure({
      aws_cognito_region: aws_cognito_region,
      aws_user_pools_id: aws_user_pools_id,
      aws_user_pools_web_client_id: aws_user_pools_web_client_id,
    });
    yield put(getInitSuccess());
  } catch (error: any) {
    yield put(enqueueErrorAction({ msg: getErrorMessage(error) }));
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function* sagas() {
  yield takeLatest(getInit().type, handleStartApp);
}

export default sagas;
