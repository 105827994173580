const textLight = {
  primary: 'rgba(52, 49, 76, 1)',
  secondary: 'rgba(52, 49, 76, 0.54)',
  disabled: 'rgba(52, 49, 76, 0.38)',
  hint: 'rgba(52, 49, 76, 0.38)',
  textualDarkBLue: 'rgba(51, 77, 110, 1)',
  darkBlue: 'rgba(20, 30, 97, 1)',
  white: '#FFFFFF',
};
const errorColor = {
  main: '#FF3D57',
};

export const themeColors = {
  white: {
    palette: {
      type: 'light',
      primary: {
        main: '#FFFFFF',
        contrastText: '#334D6E',
      },
      secondary: {
        main: '#FFFFFF',
        contrastText: '#334D6E',
      },
      error: errorColor,
      text: textLight,
    },
  },
  green: {
    palette: {
      type: 'light',
      primary: {
        main: '#29A370',
        contrastText: '#334D6E',
      },
      secondary: {
        main: '#FFFFFF',
        contrastText: '#334D6E',
      },
      error: errorColor,
      text: textLight,
    },
  },
};
