import { AxiosResponse } from 'axios';
import { apiClient } from '../commons/axios';
import { MetabaseRequest, MetabaseResponse } from '../models/metabase';

export const getMetabaseToken = (metabaseRequest: MetabaseRequest): Promise<AxiosResponse<MetabaseResponse>> => {
  const { dashboard_id, delegate_Token, account_id, ...params } = metabaseRequest;
  const overridingHeaders: Record<string, string> = {};
  if (delegate_Token) {
    overridingHeaders.authorization = `Bearer ${delegate_Token}`;
  }
  if (account_id) {
    overridingHeaders.account_id = account_id;
  }
  return apiClient.post(`/v1/tenants/reports/metabase/dashboards/${dashboard_id}/token`, params, {
    headers: { ...overridingHeaders },
  });
};
