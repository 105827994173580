import React from 'react';
import { useQueryParams } from '../../hooks/useQueryParams';
import DelegateAccessIframe from '../DelegateAccess/iframe';

type FilterParams = {
  id?: string;
  partner_id?: string;
  tenant_id?: string;
  partner_link_ref?: string;
  partner_customer_id?: string;
  intent?: string;
};

const PatLinkDelegateAccess: React.FunctionComponent = () => {
  const params = useQueryParams<FilterParams>();

  return <DelegateAccessIframe title="Pre-approved Transaction Links (PAT)" component="pat-links" params={params} />;
};

export default PatLinkDelegateAccess;
