import { makeStyles } from '@material-ui/core/styles';

export const useTxnDetailStyles = makeStyles(() => ({
  sectionSummary: {
    fontFamily: 'Open Sans',
    fontSize: '15px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '20px',
    letterSpacing: '0.25px',
    textAlign: 'left',
  },
  label: {
    color: '#787A91',
    padding: 12,
  },
  alignRight: {
    textAlign: 'right',
    wordBreak: 'break-word',
    padding: 12,
  },
  section: {
    padding: '36px 18px 20px 22px',
  },
}));
