import React, {
  // useContext,
  useEffect,
  useState,
} from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import useAuth from 'app/hooks/auth/useAuth';
import { LoginState } from 'app/hooks/auth/utils';
const AuthGuard: React.FunctionComponent = ({ children }) => {
  const { loginState } = useAuth();
  const [previouseRoute, setPreviousRoute] = useState<string | undefined>(undefined);
  const { pathname } = useLocation();
  useEffect(() => {
    if (previouseRoute !== null) {
      setPreviousRoute(pathname);
    }
  }, [pathname, previouseRoute]);

  if (loginState === LoginState.LOGGED_IN) return <>{children}</>;
  else {
    return (
      <Redirect
        to={{
          pathname: '/session/signin',
          state: { redirectUrl: previouseRoute },
        }}
      />
    );
  }
};

export default AuthGuard;
