import React from 'react';
import Logo from '../logo/logo';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import useSettings from '../../hooks/useSettings';
import LogoCompact from '../logo/logocompact';

const useStyles = makeStyles(() => ({
  brand: {
    padding: '18px 18px 10px 24px',
    color: '#109CF1',
    borderBottom: '1px solid',
    borderBottomColor: '#EBEFF2',
  },
  hideOnCompact: {
    display: 'none',
  },
  logo: {
    cursor: 'pointer',
  },
}));

interface BrandProps {
  className?: string;
  mode?: string;
  children?: React.ReactNode;
  onLogoClick?: () => void;
}

const Brand: React.FunctionComponent<BrandProps> = ({ children, onLogoClick, className }: BrandProps) => {
  const classes = useStyles();
  const { settings } = useSettings();
  const leftSidebar = settings.layout1Settings.leftSidebar;
  const { mode } = leftSidebar;

  const onClick = () => {
    onLogoClick && onLogoClick();
  };

  return (
    <div className={clsx('flex items-center justify-between', classes.brand, className)}>
      <div className="flex items-center" onClick={onClick}>
        {mode === 'compact' ? <LogoCompact className={clsx(classes.logo)} /> : <Logo className={clsx(classes.logo)} />}
      </div>
      <div
        className={clsx({
          sidenavHoverShow: true,
          [classes.hideOnCompact]: mode === 'compact',
        })}
        hidden
      >
        {children || null}
      </div>
    </div>
  );
};

export default Brand;
