import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface EntityState {
  entitiesSchemas?: any;
  currentEntity?: {
    type: string;
    schema: any;
    data?: any;
    tenant: TenantInfo;
  };
}

export interface TenantInfo {
  tenantId: string;
  tenantSlug: string;
  tenantDelegateToken?: string;
}

const initialState: EntityState = {
  entitiesSchemas: undefined,
  currentEntity: undefined,
};

const entity = createSlice({
  name: 'entity',
  initialState,
  reducers: {
    setEntitiesSchemas: (state, action: PayloadAction<{ schemas: any }>) => {
      state.entitiesSchemas = action.payload.schemas;
    },
    setCurrentEntity: (state, action: PayloadAction<{ type: string; schema: any; data?: any; tenant: any }>) => {
      state.currentEntity = {
        type: action.payload.type,
        schema: action.payload.schema,
        data: action.payload.data,
        tenant: action.payload.tenant,
      };
    },
  },
});

export const { setEntitiesSchemas, setCurrentEntity } = entity.actions;
export default entity.reducer;
