import layout1Settings from './Layout1/Layout1Settings';
import { themes } from '../Theme/initThemes';
import { CustomerPath, MerchantPath, TransactionPath } from '../../commons';

export const LayoutSettings = {
  activeLayout: 'layout1',
  activeTheme: 'green',
  perfectScrollbar: false,
  themes: themes,
  layout1Settings,
  sidebar: {
    mode: 'open',
  },
  // Footer options
  footer: {
    show: false,
    fixed: false,
    theme: 'white',
  },
  navigations: [
    {
      name: 'Dashboard',
      path: '/dashboard',
      icon: 'dashboard',
    },
    {
      name: 'Transactions',
      path: TransactionPath,
      icon: 'view_agenda',
    },
    {
      name: 'Customers',
      path: CustomerPath,
      icon: 'person',
    },
    {
      name: 'Partners',
      path: MerchantPath,
      icon: 'person',
    },
    {
      name: 'Reports',
      path: '/reports',
      icon: 'assessment',
    },
    {
      type: 'divider',
    },
    {
      name: 'User Management',
      path: '/user',
      icon: 'people',
    },
    {
      name: 'My Account',
      path: '/myaccount',
      icon: 'person',
    },
  ],
};
