import { AuditLogV2Metadata } from 'app/models/audit';
import {
  httpMethodToActionMapping,
  auditActorTypeDisplayNameMapping,
  auditResourceTypeDisplayNameMapping,
} from '../../constants';
import { convertToStartCase } from 'utils';

export const auditAction = (metadata: AuditLogV2Metadata): string => {
  return metadata.http_method
    ? `API ${httpMethodToActionMapping[metadata.http_method] || metadata.http_method}`
    : `${convertToStartCase(metadata.event_trigger)}`;
};

export const auditActorType = (actorType: string): string => {
  return auditActorTypeDisplayNameMapping[actorType] || convertToStartCase(actorType);
};

export const auditResourceType = (resourceType: string): string => {
  return auditResourceTypeDisplayNameMapping[resourceType] || convertToStartCase(resourceType);
};
