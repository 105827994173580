import React from 'react';
import { Grid, LinearProgress, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';

const CustomLinearProgress = withStyles(() => ({
  root: {
    borderRadius: 2,
    background: 'rgba(0, 0, 0, 0.1)',
  },
}))(LinearProgress);
interface ProgressBarProps {
  value?: number | undefined;
  color?: 'primary' | 'secondary' | undefined;
  text: string | React.ReactNode;
  spacing?: 0 | 2 | 1 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | undefined;
  coloredText?: unknown;
  className?: string | undefined;
}
const ProgressBar: React.FunctionComponent<ProgressBarProps> = (props) => {
  const { value = 75, color = 'primary', text = '', spacing = 2, coloredText = false, className } = props;
  return (
    <Grid container spacing={spacing} alignItems="center" className={className}>
      <Grid item xs={text ? 8 : 12}>
        <CustomLinearProgress color={color} value={value} variant="determinate"></CustomLinearProgress>
      </Grid>
      {text !== '' && (
        <Grid item xs={text ? 4 : false}>
          <Typography color={color}>
            <small className={`${coloredText ? '' : 'text-muted'}`}>{text}</small>
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};

export default ProgressBar;
