/* eslint-disable @typescript-eslint/no-explicit-any */
import { put, takeLatest } from 'redux-saga/effects';
import * as actions from 'app/redux/notifier/notifier.actions';
import { enqueueSnackbar, removeSnackbar } from '../notifier/notifier.actions';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function* sagas() {
  yield takeLatest(actions.removeSnackbarAction, removeSnackbarHandler);
  yield takeLatest(actions.enqueueSuccessAction, enqueueSuccessHandler);
  yield takeLatest(actions.enqueueErrorAction, enqueueErrorHandler);
  yield takeLatest(actions.enqueueWarningAction, enqueueWarningHandler);
}

function* removeSnackbarHandler({ payload }: ReturnType<typeof actions.removeSnackbarAction>) {
  yield put(removeSnackbar(payload));
}

function* enqueueSuccessHandler({ payload }: ReturnType<typeof actions.enqueueSuccessAction>) {
  const { msg } = payload;
  const key = new Date().getTime() + Math.random();
  yield put(
    enqueueSnackbar({
      notification: {
        key,
        message: msg,
        options: {
          key: key,
          variant: 'success',
        },
      },
    }),
  );
}

function* enqueueErrorHandler({ payload }: ReturnType<typeof actions.enqueueErrorAction>) {
  const { msg } = payload;
  const key = new Date().getTime() + Math.random();
  yield put(
    enqueueSnackbar({
      notification: {
        key,
        message: msg,
        options: {
          key: key,
          variant: 'error',
        },
      },
    }),
  );
}

function* enqueueWarningHandler({ payload }: ReturnType<typeof actions.enqueueErrorAction>) {
  const { msg } = payload;
  const key = new Date().getTime() + Math.random();
  yield put(
    enqueueSnackbar({
      notification: {
        key,
        message: msg,
        options: {
          key: key,
          variant: 'warning',
        },
      },
    }),
  );
}

export default sagas;
