import React from 'react';
import { useQueryParams } from '../../hooks/useQueryParams';
import DelegateAccessIframe from '../DelegateAccess/iframe';

type FilterParams = {
  id?: string;
  merchant_id?: string;
  customer?: string;
  customer_id?: string;
  merchant_reference_id?: string;
  tenant_reference_id?: string;
  status?: string;
};

const TransactionDelegateAccess: React.FunctionComponent = () => {
  const params = useQueryParams<FilterParams>();

  return <DelegateAccessIframe title="Transactions" component="transactions" params={params} />;
};

export default TransactionDelegateAccess;
