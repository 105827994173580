/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Grid, Modal } from '@material-ui/core/';
import { useTranslation } from 'react-i18next';
import { AuditLogV2 } from 'app/models/audit';
import clsx from 'clsx';
import DetailCard from 'app/components/Card/DetailCard';
import { convertToStartCase } from 'utils';
import { auditAction, auditActorType, auditResourceType } from './helpers';

const useStyles = makeStyles(() => ({
  root: {
    overflow: 'scroll',
  },
  modal: {
    overflow: 'hidden',
  },
  loading: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  header: {
    margin: '51px 83px 0px 84px',
    fontFamily: 'Open Sans',
    fontSize: '22px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '20px',
    letterSpacing: '0.25px',
    textAlign: 'center',
  },
  date: {
    fontFamily: 'Open Sans',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '20px',
    letterSpacing: '0.25px',
    textAlign: 'center',
    marginTop: '11px',
  },
  main: {
    border: '1px solid #C2CFE0',
    boxSizing: 'border-box',
    borderRadius: '8px',
    margin: '20px 60px 0px 61px',
  },
}));

interface Props {
  auditLog: AuditLogV2;
  className?: string;
  onClose?: () => void;
  isOpen: boolean;
}

const AuditDetailsModal: React.FunctionComponent<Props> = ({ auditLog, className, isOpen, onClose }: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const handleClose = () => {
    if (onClose) {
      onClose();
    }
  };

  return (
    <Modal
      open={isOpen}
      className={classes.modal}
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <div className={clsx(classes.root, className)}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Box className={classes.header} justifyContent="center">
              {t('audit.auditDetails')}
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box className={classes.main}>
              <DetailCard
                title={t('audit.auditInformation')}
                data={[
                  { title: 'Audit Id', value: auditLog.id },
                  {
                    title: 'Action',
                    value: auditAction(auditLog.metadata),
                  },
                  { title: 'Action Id', value: auditLog.trigger_uid },
                  { title: 'Session Id', value: auditLog.session_uid },
                  {
                    title: 'Actor Type',
                    value: auditActorType(auditLog.actor_type),
                  },
                  { title: 'Actor Id', value: auditLog.actor_uid },
                  {
                    title: 'Resource Type',
                    value: auditResourceType(auditLog.resource_type),
                  },
                  { title: 'Resource Id', value: auditLog.resource_id },
                  { title: 'IP Address', value: auditLog.metadata.ip_address },
                  { title: 'User Agent', value: auditLog.metadata.user_agent },
                ]}
              />
              <DetailCard
                title={t('audit.additionalInformation')}
                data={[
                  { title: 'HTTP Method', value: auditLog.metadata.http_method },
                  { title: 'HTTP Path', value: auditLog.metadata.http_path, isCopyValue: true },
                  { title: 'Queue Name', value: auditLog.metadata.queue_name },
                  { title: 'Topic Name', value: auditLog.metadata.topic_name },
                  { title: 'Event Trigger', value: convertToStartCase(auditLog.metadata.event_trigger) },
                  { title: 'Headers', value: JSON.stringify(auditLog.headers), isCopyValue: true },
                  { title: 'Payload', value: auditLog.payload, isCopyValue: true },
                  { title: 'Response', value: auditLog.response || '{}', isCopyValue: true },
                  { title: 'File Name', value: auditLog.metadata.file_name },
                  { title: 'File Source', value: auditLog.metadata.file_source },
                ]}
              />
            </Box>
          </Grid>
        </Grid>
      </div>
    </Modal>
  );
};

export default AuditDetailsModal;
