/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Notification } from 'app/models/notification';
import { filter } from 'lodash';

export interface NotifierState {
  notifications: Notification[];
}

const initialState: NotifierState = {
  notifications: [],
};

const notifier = createSlice({
  name: `notifier`,
  initialState,
  reducers: {
    enqueueSnackbar: (state, action: PayloadAction<{ notification: any }>) => {
      const { notification } = action.payload;
      state.notifications = [...state.notifications, ...[notification]];
    },
    removeSnackbar: (state, action: PayloadAction<{ key: any }>) => {
      const { key } = action.payload;
      state.notifications = filter(state.notifications, (n) => n.key !== key);
    },
  },
});

export const { enqueueSnackbar, removeSnackbar } = notifier.actions;

export default notifier.reducer;
