import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'app/redux/store';
import { useCallback } from 'react';
import { Paging } from '../../models/paging';
import DataGridFilterColumn from '@fabric/ui/src/components/DataGrid/filterColumn';
import { SorterResult } from 'antd/lib/table/interface';
import { Tenant } from 'app/models/tenant';
import {
  getTenantDetailsAction,
  getTenantsAction,
  editTenantAction,
  deleteTenantAction,
} from 'app/redux/tenant/tenant.actions';

const useTenant = () => {
  const dispatch = useDispatch();

  const { tenants, selected, paging } = useSelector((state: RootState) => state && state.tenant);

  const { requests } = useSelector((state: RootState) => state && state.ui);
  const getTenants = useCallback(
    ({ page_size, page }: Paging, criterias?: DataGridFilterColumn[], sorter?: SorterResult<Tenant>[]) => {
      dispatch(getTenantsAction({ page_size, page, criterias, sorter }));
    },
    [dispatch],
  );

  const getTenantDetails = useCallback(
    (id: string) => {
      dispatch(getTenantDetailsAction({ id }));
    },
    [dispatch],
  );

  const editTenantDetails = useCallback(
    ({ tenant, history }) => {
      dispatch(editTenantAction({ tenant, history }));
    },
    [dispatch],
  );

  const deleteTenant = useCallback(
    (
      tenant: Tenant,
      { page_size, page }: Paging,
      criterias?: DataGridFilterColumn[],
      sorter?: SorterResult<Tenant>[],
    ) => {
      dispatch(
        deleteTenantAction({
          tenant,
          page,
          page_size,
          criterias,
          sorter,
        }),
      );
    },
    [dispatch],
  );

  return {
    tenants,
    paging,
    selected,
    getTenants,
    getTenantDetails,
    editTenantDetails,
    deleteTenant,
    requests,
  };
};

export default useTenant;
