import React, { useEffect, useState, useCallback } from 'react';
import { Card, Grid, CircularProgress, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { useTranslation } from 'react-i18next';
import QRCode from 'qrcode';
import { CognitoUser } from 'app/models/cognitoUser';
import ReactInputVerificationCode from 'react-input-verification-code';
import useAuth from 'app/hooks/auth/useAuth';
import { setupTotpActionName, confirmTotpActionName } from 'app/redux/authentication/authentication.actions';
import useUI from 'app/hooks/useUi';
import { LoginState } from 'app/hooks/auth/utils';

const useStyles = makeStyles(() => ({
  cardHolder: {
    background: '#F5F6F8',
  },
  card: {
    width: 500,
    borderRadius: 12,
    margin: '1rem',
  },
  left: {
    padding: '15px 25px 25px 25px',
  },
  txtMfaTitle: {
    fontFamily: 'Poppins',
    fontSize: '22px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '26px',
    letterSpacing: '0.10000000149011612px',
    textAlign: 'center',
  },
  mfaSetupDesc: {
    fontFamily: 'Poppins',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '26px',
    letterSpacing: '0.10000000149011612px',
    textAlign: 'center',
  },
  font: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
  },
  warning: {
    fontSize: '14px',
    lineHeight: '18px',
    paddingLeft: '32px',
  },
  btnReset: {
    background: '#29A370',
    boxShadow: '0px 4px 10px rgba(16, 156, 241, 0.24)',
    borderRadius: 4,
    fontWeight: 600,
    fontSize: '13px',
    lineHeight: '20px',
    textAlign: 'center',
    letterSpacing: ' 0.1px',
    height: '48px',
    color: '#FFFFFF',
  },
}));

const MfaSetup: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const { setupTotp, confirmTotp } = useAuth();
  const classes = useStyles();
  const { getRequestByName } = useUI();

  const { user, loginState, secretKey } = useSelector((state: RootState) => state && state.authentication);

  const [qrCode, setQrCode] = useState<string>();
  const [code, setCode] = useState('');

  const isSetupTotpLoading = getRequestByName({
    name: setupTotpActionName,
  })?.loading;

  const isConfirmTotpLoading = getRequestByName({
    name: confirmTotpActionName,
  })?.loading;

  useEffect(() => {
    if (user && loginState === LoginState.MFA_SETUP) {
      setupTotp(user);
    }
  }, [user, loginState]);

  const generateQRCode = useCallback(
    async (cognitoUser: CognitoUser, secret: string, email?: string): Promise<string> => {
      const issuer = 'OpenFabric';
      const userName = email || cognitoUser.username;
      const totpCode = `otpauth://totp/${issuer}:${userName}?secret=${secret}&issuer=${issuer}`;
      const qrCodeImageSource = await QRCode.toDataURL(totpCode);
      return qrCodeImageSource;
    },
    [],
  );

  useEffect(() => {
    if (secretKey && user) {
      generateQRCode(user, secretKey).then((imageSource) => {
        setQrCode(imageSource);
      });
    }
  }, [generateQRCode, secretKey, user]);

  const handleVerificationCodeChanged = async (data: string) => {
    const codeString = data.replaceAll('·', '');
    setCode(data);
    if (codeString.length === 6 && user) {
      confirmTotp(user, codeString);
    }
  };

  return (
    <div className={clsx('flex justify-center items-center  min-h-full-screen', classes.cardHolder)}>
      <Card className={classes.card}>
        <Grid container>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <div className={classes.left}>
              <div className="p-8 flex justify-center items-center">
                <img className="w-200 p-2" src="/logo_dark.png" alt="" />
              </div>
              <div className="flex justify-center items-center">
                <Box>
                  <Box display="flex" justifyContent="center" className={classes.txtMfaTitle}>
                    {t('mfa.setup')}
                  </Box>
                  <Box display="flex" justifyContent="center" className={classes.mfaSetupDesc} paddingTop="10px">
                    {t('mfa.setupDesc')}
                  </Box>
                </Box>
              </div>
              <Box display="flex" justifyContent="center" paddingTop="10px">
                {isSetupTotpLoading ? (
                  <CircularProgress />
                ) : (
                  <img data-amplify-qrcode src={qrCode} alt="qr code" width="228" height="228" />
                )}
              </Box>
              <Box>
                <Box display="flex" justifyContent="center" className={classes.mfaSetupDesc} paddingTop="10px">
                  {t('mfa.enterCodeDesc')}
                </Box>
                <Box display="flex" justifyContent="center" className={classes.mfaSetupDesc} paddingTop="20px">
                  {t('mfa.enterCode')}
                </Box>
              </Box>
              <Box display="flex" justifyContent="center" paddingTop="10px" className="custom-styles">
                <ReactInputVerificationCode
                  length={6}
                  value={code}
                  onChange={handleVerificationCodeChanged}
                  autoFocus={true}
                />
              </Box>
              {isConfirmTotpLoading && <CircularProgress />}
            </div>
          </Grid>
        </Grid>
      </Card>
    </div>
  );
};

export default MfaSetup;
