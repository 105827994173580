import React from 'react';
import { ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import CssVars from './CssVars';
import useSettings from '../../hooks/useSettings';

const Theme: React.FunctionComponent = ({ children }) => {
  const { settings } = useSettings();
  const activeTheme = { ...settings.themes[settings.activeTheme] };
  return (
    <ThemeProvider theme={activeTheme}>
      <CssBaseline />
      <CssVars> {children} </CssVars>
    </ThemeProvider>
  );
};

export default Theme;
