import React from 'react';
import { Layouts } from './index';
import { Suspense, useSettings } from '@fabric/ui';

const Layout: React.FunctionComponent = (props) => {
  const { settings } = useSettings();
  const Layout = Layouts[settings.activeLayout];
  return (
    <Suspense>
      <Layout {...props} />
    </Suspense>
  );
};

export default Layout;
