import React, { useState } from 'react';
import { Tenant } from 'app/models/tenant';
import { Select } from 'antd';
import { useDispatch } from 'react-redux';
import { useSettings } from '@fabric/ui';
import { LOCAL_STORAGE_KEYS } from '@fabric/ui/src/commons';
import { useTenantSelectionList } from '../../../queries/tenant/tenant.query';
import { enqueueSnackbar } from '../../../redux/notifier/notifier.slice';
import { getErrorMessage } from '@fabric/ui/src/utils';

const ValueSeparator = '::';

const parseTenantValue = (value: string) => {
  const values = value.split(ValueSeparator);
  localStorage.setItem(LOCAL_STORAGE_KEYS.ACCOUNT_ID, values[0]);
  return {
    seletedTenantId: values[0],
    seletedTenantSlug: values[1],
  };
};

const TenantSelection: React.FunctionComponent = () => {
  const [search, setSearch] = useState<string>();
  const { settings, updateSettings } = useSettings();
  const dispatch = useDispatch();

  const { isLoading, isError, error, data, isFetching, fetchNextPage, refetch } = useTenantSelectionList(search);

  if (isError) {
    const key = new Date().getTime() + Math.random();
    dispatch(
      enqueueSnackbar({
        notification: {
          key,
          message: getErrorMessage(error),
          options: {
            key: key,
            variant: 'error',
          },
        },
      }),
    );
  }

  const onTenantChanged = (value: string) => {
    if (updateSettings) {
      updateSettings({
        ...settings,
        ...parseTenantValue(value.trim()),
      });
    }
  };

  const handleScroll = (event: any) => {
    const { target } = event;
    if (target.scrollTop + target.offsetHeight > target.scrollHeight - 10) {
      fetchNextPage();
    }
  };

  return (
    <React.Fragment>
      <Select
        style={{ width: '100%' }}
        onClick={() => {
          refetch();
        }}
        onChange={onTenantChanged}
        onPopupScroll={handleScroll}
        loading={isLoading || isFetching}
        onSearch={(v) => setSearch(v)}
        showSearch={true}
        searchValue={search}
        filterOption={false}
      >
        {data?.pages.map((page) =>
          page.data.map((t: Tenant) => (
            <Select.Option key={t.id} value={`${t.id}${ValueSeparator}${t.slug}`}>
              {t.name}
            </Select.Option>
          )),
        )}
      </Select>
    </React.Fragment>
  );
};

export default TenantSelection;
