import { RouteConfigWrapper } from '@fabric/ui/src/commons';
import DelegateAccessIframe from '../DelegateAccess/iframe';
import AuditLog from './audit';

export const AuditPath = '/audit';

const auditRoutes: RouteConfigWrapper[] = [
  {
    route: {
      path: AuditPath,
      exact: true,
      component: () => <DelegateAccessIframe title="Audit Trail" component="audit" />,
    },
  },
  {
    route: {
      path: `${AuditPath}-osp`,
      exact: true,
      component: () => <AuditLog />,
    },
  },
];

export default auditRoutes;
