import React, { useEffect, useState } from 'react';
import { Chip, InputAdornment, TextField } from '@material-ui/core';
import DataGridFilterColumn from '@fabric/ui/src/components/DataGrid/filterColumn';

export const TextInputMultiple: React.FC<TextInputMultipleProps> = ({
  col,
  values,
  formik,
  setCurrentSelection,
  isUniqueFilter,
}) => {
  const { touched, errors, setFieldValue } = formik;
  const [currentText, setCurrentText] = useState('');
  const [textList, setTextList] = useState([] as string[]);

  const appendFilter = () => {
    const trimmedText = currentText.trim();

    if (trimmedText) {
      setTextList([...textList, trimmedText]);
      setCurrentText('');
    }
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' || event.key === ',') {
      event.preventDefault();
      if (currentText.trim() !== '') {
        appendFilter();
      }
    }
  };

  const handleChipDelete = (index: number) => {
    const newList = [...textList];
    newList.splice(index, 1);
    setTextList(newList);
  };

  useEffect(() => {
    if (isUniqueFilter) {
      setCurrentSelection(col.field);
    }

    setFieldValue(col.field, textList);
  }, [textList]);

  useEffect(() => {
    if (Array.isArray(values[col.field])) {
      setTextList(values[col.field]);
    } else {
      const queryParamValue = values[col.field];
      if (queryParamValue.includes('|')) {
        setTextList(queryParamValue.split('|'));
      } else if (queryParamValue) {
        setTextList(queryParamValue.split(','));
      }
    }
  }, []);

  return (
    <TextField
      fullWidth
      id={col.field}
      name={col.field}
      label={col.name}
      value={currentText}
      onBlur={(...params) => {
        formik.handleBlur(...params);
        appendFilter();
      }}
      onChange={(...params) => {
        setCurrentText(params[0].target.value);
      }}
      onKeyDown={handleKeyPress}
      InputProps={{
        startAdornment: (
          <InputAdornment
            position="start"
            style={{ flexDirection: 'row', flexWrap: 'wrap', maxWidth: '100%', maxHeight: '100%', height: '100%' }}
          >
            {textList.map((text: string, index: number) => (
              <Chip
                key={index}
                label={text}
                onDelete={() => handleChipDelete(index)}
                style={{ margin: 4, height: 'auto' }}
              />
            ))}
          </InputAdornment>
        ),
      }}
      error={touched[col.field] && Boolean(errors[col.field])}
      helperText={touched[col.field] && errors[col.field]}
    />
  );
};

interface TextInputMultipleProps {
  col: DataGridFilterColumn;
  values: any;
  formik: any;
  isUniqueFilter?: boolean;
  setCurrentSelection: (selection: string) => void;
}
