import { RouteConfigWrapper } from '@fabric/ui/src/commons';
import Index from './dashboard.index';

const dashboardRoutes: RouteConfigWrapper[] = [
  {
    route: { path: '/dashboard', component: Index },
  },
];

export default dashboardRoutes;
