import React from 'react';
import 'fontsource-roboto';
import 'fake-db';
import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import RouterConfig from 'app/navigation/RouterConfig';
import { GlobalCss, Theme, Suspense } from '@fabric/ui';
import { SettingsProvider } from '@fabric/ui';
import { Provider } from 'react-redux';
import { store, persistor } from 'app/redux/store';
import { AppContext } from '@fabric/ui';
import routes from 'app/navigation/Routes';
import { LayoutSettings } from 'app/components/Layout/settings';
import { LocalizationProvider } from '@material-ui/pickers';
import DateFnsUtils from '@material-ui/pickers/adapter/date-fns';
import Notifier from 'app/components/Notifier/Notifier';
import { SnackbarProvider } from 'notistack';
import { QueryClient, QueryClientProvider } from 'react-query';

import Init from './Init';

export const queryClient = new QueryClient();

const App: React.FunctionComponent = () => {
  return (
    <LocalizationProvider dateAdapter={DateFnsUtils}>
      <AppContext.Provider value={{ routes }}>
        <QueryClientProvider client={queryClient}>
          <Provider store={store}>
            <Init />
            <PersistGate loading={null} persistor={persistor}>
              <SettingsProvider settings={LayoutSettings}>
                <Theme>
                  <SnackbarProvider maxSnack={3} autoHideDuration={3000} preventDuplicate={true}>
                    <Notifier />
                    <GlobalCss />
                    <BrowserRouter>
                      <Suspense>
                        <RouterConfig />
                      </Suspense>
                    </BrowserRouter>
                  </SnackbarProvider>
                </Theme>
              </SettingsProvider>
            </PersistGate>
          </Provider>
        </QueryClientProvider>
      </AppContext.Provider>
    </LocalizationProvider>
  );
};

export default App;
