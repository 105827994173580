import jwtDecode from 'jwt-decode';

export const setLocalStorage = (data: { key: string; value: string }): void => {
  const { key, value } = data;
  if (key) localStorage.setItem(key, value);
};

export const getLocalStorage = (data: { key: string }): any => {
  const { key } = data;
  if (key) {
    return localStorage.getItem(key);
  }
};

export const removeLocalStorage = (data: { key: string }): any => {
  const { key } = data;
  if (!key) {
    return;
  }

  localStorage.removeItem(key);
};

export const isValidToken = (accessToken: string): boolean => {
  if (!accessToken) {
    return false;
  }
  const decodedToken = jwtDecode<{ exp: number }>(accessToken);
  const currentTime = Date.now() / 1000;
  return decodedToken.exp > currentTime;
};

export enum LoginState {
  COMPLETING_PASSWORD,
  MFA_SETUP,
  MFA_CHALLENGE,
  LOGGED_IN,
  LOGGED_OUT,
}

export const getRedirectionPathFromLoginState = (loginState: LoginState): string => {
  switch (loginState) {
    case LoginState.COMPLETING_PASSWORD:
      return '/session/complete-password';
    case LoginState.MFA_SETUP:
      return '/session/mfa-setup';
    case LoginState.MFA_CHALLENGE:
      return '/session/mfa-challenge';
    case LoginState.LOGGED_IN:
      return '/tenant';
    case LoginState.LOGGED_OUT:
      return '/session/signin';
    default:
      return '/session/signin';
  }
};
