/* eslint-disable prefer-const */
import { themeColors } from './themeColors';
import { createTheme, ThemeOptions } from '@material-ui/core/styles';
import { forEach, merge } from 'lodash';
import themeOptions from './themeOptions';

function createThemes() {
  let themes = {};

  // @ts-ignore
  forEach(
    themeColors,
    (value, key) => (themes[key] = createTheme(merge({}, themeOptions as unknown as ThemeOptions, value))),
  );

  return themes;
}
export const themes = createThemes();
