export enum BillingType {
  FIXED_PER_UNIT = 'PER_UNIT',
  FIXED_TIERED = 'TIERED',
  VARIABLE_PER_UNIT = 'VARIABLE_PER_UNIT',
  VARIABLE_TIERED = 'VARIABLE_TIERED',
}

export enum BillingPeriodUnit {
  Daily = 'Daily',
  Weekly = 'Weekly',
  Monthly = 'Monthly',
  Yearly = 'Yearly',
}

export enum BillingCurrency {
  USD = 'USD',
  SGD = 'SGD',
}

export const billingTypes = [
  BillingType.FIXED_TIERED,
  BillingType.FIXED_PER_UNIT,
  BillingType.VARIABLE_PER_UNIT,
  BillingType.VARIABLE_TIERED,
];
export const billingPeriodUnits = [
  BillingPeriodUnit.Daily,
  BillingPeriodUnit.Weekly,
  BillingPeriodUnit.Monthly,
  BillingPeriodUnit.Yearly,
];
export const billingCurrencies = [BillingCurrency.USD, BillingCurrency.SGD];

export const allBillingTypes = [
  { label: 'Fixed Per Unit', value: BillingType.FIXED_PER_UNIT },
  { label: 'Fixed Tiered', value: BillingType.FIXED_TIERED },
  { label: 'Variable Per Unit', value: BillingType.VARIABLE_PER_UNIT },
  { label: 'Variable Tiered', value: BillingType.VARIABLE_TIERED },
];

export const httpMethodToActionMapping = {
  GET: 'Read',
  POST: 'Create',
  DELETE: 'Delete',
  PATCH: 'Update',
  PUT: 'Update',
};

export const auditResourceTypeDisplayNameMapping = {
  pat_link: 'PAT Link',
  pba_account_device: 'PBA Secure Device Wallet',
  pba_account: 'PBA Account',
  pba_transaction: 'PBA Transaction',
};

export const auditActorTypeDisplayNameMapping = {
  osp_user: 'OSP User',
  tsp_user: 'TSP User',
  tenant_api_client: 'Tenant API - Client',
  partner_api_client: 'Partner API - Client',
};
