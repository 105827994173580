/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Button, Grid, IconButton, Typography } from '@material-ui/core/';
import { CardMenu, ConfirmationModal } from '@fabric/ui';
import { useTranslation } from 'react-i18next';
import DataGridFilter from '@fabric/ui/src/components/DataGrid/filter';
import DataGridFilterColumn, { DataGridFilterType } from '@fabric/ui/src/components/DataGrid/filterColumn';
import { getTenantsActionName } from 'app/redux/tenant/tenant.actions';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';
import useUI from 'app/hooks/useUi';
import { isArray } from 'lodash';
import { Table, TablePaginationConfig, Tag } from 'antd';
import { SorterResult } from 'antd/lib/table/interface';
import { getFooterOfPagination } from 'utils';
import { Tenant } from 'app/models/tenant';
import useTenant from './useTenant';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/DeleteOutlined';

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    padding: '36px 40px 0px 35px',
  },
  actionRoot: {
    display: 'inline-flex',
    alignItems: 'center',
  },
  list: {
    paddingTop: '24px',
  },
  info: {
    width: '283px',
  },
  mngUserGroup: {
    width: '313px',
    marginLeft: '25px',
  },
  textPrimary: {},
  btnUserAdd: {
    width: '160px',
    height: '38.84px',
    background: '#29A370',
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '13px',
    lineHeight: '19px',
    textAlign: 'center',
    letterSpacing: '0.01em',
    color: '#FFFFFF',
  },
  id: {
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '10px',
    lineHeight: '20px',
    /* identical to box height, or 200% */

    // display: flex;
    // align-items: center;
    letterSpacing: '0.25px',

    color: 'rgba(120, 122, 145, 0.6)',
  },
}));

const TenantList: React.FunctionComponent = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const { getRequestByName } = useUI();

  const { tenants, paging, getTenants, deleteTenant } = useTenant();
  useEffect(() => {
    getTenants({ page_size: paging.page_size, page: paging.page });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getTenants]);

  const [openDeleteModal, setOpenDeleteModal] = React.useState(false);
  const [selectedTenant, setSelectedTenant] = React.useState(null);

  const columns = [
    {
      dataIndex: 'name',
      title: t('tenant.name'),
      key: 'name',
      sorter: {
        multiple: 1,
      },
      render: (_text: string, record: Tenant) => {
        return (
          <div>
            <div>{record.name}</div>
            <div className={classes.id}>{record.id}</div>
          </div>
        );
      },
    },
    {
      dataIndex: 'slug',
      title: t('tenant.slug'),
      key: 'slug',
      sorter: {
        multiple: 1,
      },
    },
    {
      dataIndex: 'website_url',
      title: t('tenant.websiteUrl'),
      key: 'website_url',
      sorter: {
        multiple: 1,
      },
    },
    {
      title: t('tenant.status'),
      width: 120,
      render: (_text: any, tenant: Tenant) => {
        const color = tenant.is_active ? 'green' : 'volcano';
        return (
          <Box display="flex" justifyContent="center">
            <Tag color={color}>
              {tenant.is_active ? t('tenant.active').toUpperCase() : t('tenant.deactivated').toUpperCase()}
            </Tag>
          </Box>
        );
      },
    },
    {
      title: 'Actions',
      width: 120,
      render: (_text: any, tenant: Tenant) =>
        tenant.is_active ? (
          <Box>
            <IconButton color="inherit" size="small" aria-label="edit" onClick={() => onTenantEditClicked(tenant)}>
              <EditIcon fontSize="small" />
            </IconButton>
            <IconButton color="inherit" size="small" aria-label="delete" onClick={() => onTenantDeleteClicked(tenant)}>
              <DeleteIcon fontSize="small" />
            </IconButton>
          </Box>
        ) : (
          <></>
        ),
    },
  ];
  const filterColumns: DataGridFilterColumn[] = [
    {
      field: 'name',
      name: t('tenant.name'),
    },
    {
      field: 'slug',
      name: t('tenant.slug'),
    },
    {
      field: 'website_url',
      name: t('tenant.websiteUrl'),
    },
    {
      field: 'status',
      name: t('tenant.status'),
      type: DataGridFilterType.DROPDOWN,
      values: [
        {
          label: t('tenant.active'),
          value: 'active',
        },
        {
          label: t('tenant.deactivated'),
          value: 'inactive',
        },
      ],
    },
  ];

  const [criterias, setCriteria] = useState(filterColumns);

  const [sorts, setSorts] = useState<SorterResult<Tenant>[]>([]);

  const onTenantCreateClicked = (e: any) => {
    e.stopPropagation();
    history.push('/tenant/create');
  };

  const onTenantEditClicked = (row: any) => {
    history.push(`/tenant/edit/${row.id}`);
  };

  const onTenantDeleteClicked = (row: any) => {
    setOpenDeleteModal(true);
    setSelectedTenant(row);
  };

  const onDeleteTenant = () => {
    selectedTenant && deleteTenant(selectedTenant, paging, criterias, sorts);
    setOpenDeleteModal(false);
    setSelectedTenant(null);
  };

  const onCancel = () => {
    setOpenDeleteModal(false);
  };

  const onChange = (
    changedPagination: TablePaginationConfig,
    _filters: any,
    sorter: SorterResult<Tenant> | SorterResult<Tenant>[],
    _extra: any,
  ) => {
    let sorts: SorterResult<Tenant>[] = [];

    if (!isArray(sorter)) {
      sorts = [...sorts, sorter];
    } else {
      sorts = [...sorts, ...sorter];
    }

    setSorts(sorts);
    if (changedPagination.current && changedPagination.pageSize) {
      getTenants(
        {
          page: changedPagination.current,
          page_size: changedPagination.pageSize,
        },
        criterias,
        sorts,
      );
    }
  };

  const onFilter = (values: DataGridFilterColumn[]) => {
    setCriteria(values);
    getTenants({ page: 1, page_size: paging.page_size }, values);
  };

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={12} className={classes.list}>
          <CardMenu
            title={t('mangeTenants')}
            subtitle={t('mangeTenantsDesc')}
            actions={[
              <Button variant="contained" className={clsx(classes.btnUserAdd)} key={1} onClick={onTenantCreateClicked}>
                {t('tenantCreate')}
              </Button>,
              <DataGridFilter key={1} variant="outlined" onFilter={onFilter} criterias={criterias} />,
            ]}
          >
            <Table
              rowKey={'id'}
              loading={
                getRequestByName({
                  name: getTenantsActionName,
                })?.loading
              }
              columns={columns}
              dataSource={tenants}
              pagination={{
                current: paging.page,
                total: paging.total,
                pageSize: paging.page_size,
              }}
              onChange={onChange}
              size="middle"
              footer={() => (
                <Typography align={'right'}>
                  {getFooterOfPagination({
                    page: paging.page,
                    total: paging.total as number,
                    page_size: paging.page_size,
                  })}
                </Typography>
              )}
            />

            {openDeleteModal && selectedTenant && (
              <ConfirmationModal
                onSubmit={onDeleteTenant}
                onCancel={onCancel}
                open={openDeleteModal}
                title={t('tenant.confirm.title')}
              >
                <Typography>{t('tenant.confirm.msg')}</Typography>
              </ConfirmationModal>
            )}
          </CardMenu>
        </Grid>
      </Grid>
    </div>
  );
};

export default TenantList;
