import { all, fork } from 'redux-saga/effects';
import authentication from './authentication/authentication.saga';
import tenant from './tenant/tenant.saga';
import ui from 'app/redux/ui/ui.saga';
import notifier from 'app/redux/notifier/notifier.saga';
import init from './init/init.saga';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function* root() {
  yield all([fork(init), fork(authentication), fork(tenant), fork(ui), fork(notifier)]);
}
