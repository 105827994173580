import { useQuery } from 'react-query';
import { MetabaseRequest } from '../../models/metabase';
import { getMetabaseToken } from '../../services/metabaseService';

const METABASE_TOKEN_KEY = 'METABASE_TOKEN_KEY';

export const useGetMetabaseToken = (params: MetabaseRequest) => {
  return useQuery(
    METABASE_TOKEN_KEY,
    () => {
      return getMetabaseToken(params);
    },
    { keepPreviousData: true, enabled: !!params.delegate_Token },
  );
};
