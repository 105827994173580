import { createAction } from '@reduxjs/toolkit';
import { CognitoUser } from 'app/models/cognitoUser';

const loginActionName = 'LOGIN';
export const completeNewPasswordActionName = 'COMPLETE_NEW_PASSWORD';
export const setupTotpActionName = 'TOTP_SETUP';
export const confirmTotpActionName = 'TOTP_CHALLENGE';
export const confirmMfaActionName = 'MFA_CHALLENGE';
export const logoutActionName = 'LOGOUT';
export const logoutActionSuccessName = 'LOGOUT_SUCCESS';

export const loginAction = createAction<{ userName: string; password: string }>(loginActionName);

export const completeNewPasswordAction = createAction<{
  userName: string;
  oldPassword: string;
  newPassword: string;
}>(completeNewPasswordActionName);

export const setupTotpAction = createAction<CognitoUser>(setupTotpActionName);
export const confirmTotpAction = createAction<{ user: CognitoUser; totp: string }>(confirmTotpActionName);
export const confirmMfaAction = createAction<{ user: CognitoUser; mfaCode: string }>(confirmMfaActionName);

export const logoutAction = createAction(logoutActionName);
export const logoutSuccessAction = createAction(logoutActionSuccessName);
