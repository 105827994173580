import React, { useState } from 'react';
import {
  Card,
  // Checkbox,
  // FormControlLabel,
  Grid,
  Button,
  CircularProgress,
  Box,
} from '@material-ui/core';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { makeStyles } from '@material-ui/core/styles';
import useAuth from 'app/hooks/auth/useAuth';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(() => ({
  cardHolder: {
    background: '#F5F6F8',
  },
  card: {
    width: 500,
    borderRadius: 12,
    margin: '1rem',
  },
  info: {
    background: 'linear-gradient(41.53deg, #53AE78 0%, #008BDF 100%)',
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  rect: {
    background: 'linear-gradient(118.75deg, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0.3) 100%)',
    borderRadius: 20,
    width: 432,
    height: 320,
  },
  btnSignIn: {
    background: '#29A370',

    boxShadow: '0px 4px 10px rgba(16, 156, 241, 0.24)',
    borderRadius: 4,
    width: '100%',
    fontWeight: 600,
    fontSize: '13px',
    lineHeight: '20px',
    textAlign: 'center',
    letterSpacing: ' 0.1px',
    height: '48px',
    color: '#FFFFFF',
  },
  left: {
    padding: '15px 25px 15px 25px',
  },
  txtSignIn: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '32px',
    lineHeight: '84px',
    display: 'flex',
    alignItems: 'center',
    color: '#192A3E',
  },
  signUp: {
    paddingTop: '60px',
    fontWeight: 'normal',
    fontSize: '13px',
    lineHeight: '20px',
    letterSpacing: '0.1px',
    color: '#4C5862',
    flex: 'none',
    order: 0,
    flexGrow: 0,
    margin: '0px 6px',
  },
  rectW: {
    paddingTop: '173px',
  },
  manage: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '20px',
    lineHeight: '30px',
    color: '#FFFFFF',
  },
  more: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontSize: '13px',
    lineHeight: '20px',
    color: '#FFFFFF',
    opacity: '0.7',
  },
  font: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
  },
  agree: {
    fontWeight: 500,
    fontSize: '12px',
    lineHeight: '18px',
    letterSpacing: '0.2px',
    color: '#90A0B7',
    paddingLeft: '10px',
  },
  slider: {
    width: '200px',
    height: '4px',
    paddingBottom: '180px',
  },
  sliderItem1: {
    width: '32px',
    height: '4px',
    top: '726px',
    background: '#FFFFFF',
    borderRadius: '6px',
  },
  sliderItem2: {
    width: '32px',
    height: '4px',
    top: '726px',
    background: '#FFFFFF',
    opacity: 0.5,
    borderRadius: '6px',
  },
  sliderItem3: {
    width: '32px',
    height: '4px',
    top: '726px',
    background: '#FFFFFF',
    opacity: 0.5,
    borderRadius: '6px',
  },
  checkBox: {
    border: '2px solid #D5D5D5',
    boxSizing: 'border-box',
    borderRadius: '4px',
    height: '16px',
    width: '16px',
    paddingRight: '8px',
  },
  dontHaveAccount: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '13px',
    lineHeight: '20px',
    letterSpacing: '0.1px',
    textAlign: 'center',
    color: '#4C5862',
  },
  contactAdmin: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '13px',
    lineHeight: '20px',
    letterSpacing: '0.1px',
    color: 'rgba(50, 60, 71, 0.6)',
  },
}));

const CognitoLogin: React.FunctionComponent = () => {
  const [loading, setLoading] = useState(false);
  const [userInfo, setUserInfo] = useState({
    email: '',
    password: '',
    agreement: false,
  });
  const { login } = useAuth();
  const { err } = useSelector((state: RootState) => state && state.authentication);
  const classes = useStyles();
  const { t } = useTranslation();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleChange = (props: { target: any }) => {
    const temp = { ...userInfo };
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    // eslint-disable-next-line react/prop-types
    temp[props.target.name] = props.target.value;
    setUserInfo(temp);
  };

  const handleFormSubmit = async () => {
    setLoading(true);
    login({ userName: userInfo.email, password: userInfo.password });
    setLoading(false);
  };

  return (
    <div className={clsx('flex justify-center items-center  min-h-full-screen', classes.cardHolder)}>
      <Card className={classes.card}>
        <Grid container>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <div className={classes.left}>
              <div className="p-4 flex justify-center items-center">
                <img className="w-200 p-2" src="/logo_dark.png" alt="" />
              </div>
              <div className="flex justify-center items-center">
                <span className={classes.txtSignIn}>{t('signIn')}</span>
              </div>
              <div className="p-4 h-full bg-light-gray relative">
                <ValidatorForm onSubmit={handleFormSubmit}>
                  <TextValidator
                    className="mb-6 w-full"
                    variant="outlined"
                    label={t('email')}
                    onChange={handleChange}
                    type="email"
                    name="email"
                    value={userInfo.email}
                    validators={['required', 'isEmail']}
                    errorMessages={[t('requiredFieldErrMsg'), t('emailNotValid')]}
                  />
                  <TextValidator
                    className="mb-3 w-full"
                    label={t('password')}
                    variant="outlined"
                    onChange={handleChange}
                    name="password"
                    type="password"
                    value={userInfo.password}
                    validators={['required']}
                    errorMessages={[t('requiredFieldErrMsg')]}
                  />
                  {/*<FormControlLabel*/}
                  {/*    className={clsx(*/}
                  {/*        classes.font,*/}
                  {/*        classes.agree,*/}
                  {/*        'mb-3 min-w-288'*/}
                  {/*    )}*/}
                  {/*    name="agreement"*/}
                  {/*    onChange={handleChange}*/}
                  {/*    control={*/}
                  {/*        <Checkbox*/}
                  {/*            size="small"*/}
                  {/*            onChange={({*/}
                  {/*                target: { checked },*/}
                  {/*            }) =>*/}
                  {/*                handleChange({*/}
                  {/*                    target: {*/}
                  {/*                        name: 'agreement',*/}
                  {/*                        value: checked,*/}
                  {/*                    },*/}
                  {/*                })*/}
                  {/*            }*/}
                  {/*            className={classes.checkBox}*/}
                  {/*            checked={*/}
                  {/*                userInfo.agreement || true*/}
                  {/*            }*/}
                  {/*        />*/}
                  {/*    }*/}
                  {/*    label={t('keepMeLoggedIn')}*/}
                  {/*/>*/}

                  <>{err && <p className="text-error">{err}</p>}</>

                  <div className="flex flex-wrap items-center mb-4">
                    <Button
                      variant="contained"
                      className={clsx(classes.btnSignIn, classes.font)}
                      color="primary"
                      disabled={loading}
                      type="submit"
                    >
                      {t('signIn')}
                    </Button>
                    {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                  </div>
                </ValidatorForm>
              </div>
              <Box className="p-4">
                <Box display="flex" paddingTop="10px" className={clsx(classes.dontHaveAccount)}>
                  <span>{t('dontHaveAccount')}</span>
                </Box>
                <Box display="flex" paddingTop="10px" className={clsx(classes.contactAdmin)}>
                  <span>{t('contactAdmin')}</span>
                </Box>
              </Box>
              {/*<div*/}
              {/*    className={clsx(*/}
              {/*        classes.signUp,*/}
              {/*        'flex justify-center items-center',*/}
              {/*    )}*/}
              {/*>*/}
              {/*    <span>*/}
              {/*        {t('dontHaveAccount') +*/}
              {/*            ' ?' +*/}
              {/*            t('contactAdmin')}*/}
              {/*    </span>*/}
              {/*</div>*/}
            </div>
          </Grid>
        </Grid>
      </Card>
    </div>
  );
};

export default CognitoLogin;
