import * as yup from 'yup';
import { billingCurrencies, billingPeriodUnits, billingTypes } from '../../constants';

export const tenantSchema = yup.object({
  name: yup.string().trim().required('Company Name is required'),
  website_url: yup.string().trim().required('Website is required'),
  address: yup.string().trim().required('Address is required'),
  admin_first_name: yup.string().trim().required('First Name is required'),
  admin_last_name: yup.string().trim().required('Last Name is required'),
  admin_phone: yup
    .string()
    .trim()
    .required('Phone Number is required')
    .matches(/^\+(?:[0-9]●?){6,14}[0-9]$/g, 'Phone number is invalid.'),
  admin_email: yup.string().email('Enter a valid email').trim().required('Email is required'),
  billing_type: yup.string().oneOf(billingTypes).required('Billing Type is required'),
  billing_currency: yup.string().oneOf(billingCurrencies).required('Currency is required'),
  billing_period_unit: yup.string().oneOf(billingPeriodUnits).required('Period is required'),
});
