import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Backdrop, CircularProgress } from '@material-ui/core/';

const useStyles = makeStyles(() => ({
  root: {
    background: '#FFFFFF',
    border: '1.5px solid #EAEAEA',
    boxSizing: 'border-box',
    borderRadius: '5px',
    position: 'relative',
    zIndex: 0,
  },
  backdrop: {
    zIndex: 999999,
    color: '#fff',
    position: 'absolute',
  },
}));

const BoxInput: React.FunctionComponent<{
  children?: React.ReactNode;
  className?: string;
  loading?: boolean;
}> = (props) => {
  const classes = useStyles();

  return (
    <Box className={clsx(classes.root, props.className)}>
      {props.loading && (
        <Backdrop className={classes.backdrop} open={props.loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      )}

      {props.children}
    </Box>
  );
};

export default BoxInput;
