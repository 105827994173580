import Mock from './mock';

import './db/auth';
import './db/users';
import './db/transactions';
import './db/list';
import './db/notification';
import './db/tenants';

Mock.onAny().passThrough();
