import React from 'react';
import { DateTimePicker } from '@material-ui/pickers';
import { Grid, TextField, Typography } from '@material-ui/core';
import { BreakDateRange, DateEnum, getStringDate, isDate } from '../utils/utils';

export interface DateRangeInputProps {
  name?: string;
  value?: string;
  onChange?: (v: string) => void;
  disabled?: boolean;
}

export const DateRangeInput = (props?: DateRangeInputProps) => {
  const [startValue, setStartValue] = React.useState<Date | null>(null);
  const [endValue, setEndValue] = React.useState<Date | null>(null);

  React.useEffect(() => {
    const dateArr = props?.value?.split(BreakDateRange);
    if (dateArr) {
      const startDate = dateArr[0] ? new Date(dateArr[0]) : null;
      const endDate = dateArr[1] ? new Date(dateArr[1]) : null;
      setStartValue(startDate);
      setEndValue(endDate);
    } else {
      setStartValue(null);
      setEndValue(null);
    }
  }, [props?.value]);

  const onChange = (type: DateEnum, newValue: Date | null) => {
    const isValidDate = !newValue || isDate(newValue);

    if (props?.onChange && isValidDate) {
      const startDateValue = type === DateEnum.START ? newValue : startValue;
      const endDateValue = type === DateEnum.END ? newValue : endValue;
      props.onChange(`${getStringDate(startDateValue)}${BreakDateRange}${getStringDate(endDateValue)}`);
    }
    if (type === DateEnum.START) {
      setStartValue(newValue);
    } else {
      setEndValue(newValue);
    }
  };

  return (
    <Grid container direction="column">
      <Typography gutterBottom align="left">
        {props?.name}
      </Typography>
      <Grid container direction="row" spacing={2}>
        <Grid item xs={6}>
          <DateTimePicker
            renderInput={(pickerProps) => <TextField {...pickerProps} helperText={''} />}
            label={'From'}
            ampm={false}
            value={startValue}
            onChange={(newValue) => onChange(DateEnum.START, newValue)}
            disabled={props?.disabled}
          />
        </Grid>
        <Grid item xs={6}>
          <DateTimePicker
            renderInput={(pickerProps) => <TextField {...pickerProps} helperText={''} />}
            label={'To'}
            ampm={false}
            value={endValue}
            onChange={(newValue) => onChange(DateEnum.END, newValue)}
            disabled={props?.disabled}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
