import { createAction } from '@reduxjs/toolkit';
import { requestStarted, requestFinished, resetState } from './ui.slice';

const requestStartedActionName = 'REQUEST_STARTED';

const requestStartedAction = createAction<{ name: string }>(requestStartedActionName);

const requestFinishedActionName = 'REQUEST_FINISHED';

const requestFinishedAction = createAction<{ name: string }>(requestFinishedActionName);

export { requestStartedAction, requestFinishedAction, requestStarted, requestFinished, resetState };
