import { useSnackbar, SnackbarKey } from 'notistack';
import { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'app/redux/store';
import { Notification } from 'app/models/notification';
import {
  enqueueSuccessAction,
  enqueueErrorAction,
  enqueueWarningAction,
  removeSnackbarAction,
} from 'app/redux/notifier/notifier.actions';

const useNotifier = () => {
  let displayed: SnackbarKey[] = [];

  const dispatch = useDispatch();
  const { notifications } = useSelector((state: RootState) => state && state.notifier);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const storeDisplayed = (id: SnackbarKey) => {
    displayed = [...displayed, id];
  };

  const removeDisplayed = (id: SnackbarKey) => {
    displayed = [...displayed.filter((key) => id !== key)];
  };

  const displaySuccessNoti = useCallback(
    ({ msg }) => {
      dispatch(
        enqueueSuccessAction({
          msg,
        }),
      );
    },
    [dispatch],
  );

  const displayErrorNoti = useCallback(
    ({ msg }) => {
      dispatch(
        enqueueErrorAction({
          msg,
        }),
      );
    },
    [dispatch],
  );

  const displayWarningNoti = useCallback(
    ({ msg }) => {
      dispatch(
        enqueueWarningAction({
          msg,
        }),
      );
    },
    [dispatch],
  );

  useEffect(() => {
    notifications.forEach(({ key, message, options = {}, dismissed = false }: Notification) => {
      if (dismissed) {
        // dismiss snackbar using notistack
        closeSnackbar(key);
        return;
      }

      // do nothing if snackbar is already displayed
      if (displayed.includes(key)) return;

      // display snackbar using notistack
      enqueueSnackbar(message, {
        key,
        ...options,
        onClose: (event, reason, key) => {
          if (options.onClose) {
            options.onClose(event, reason, key);
          }
        },
        onExited: (_event: any, key: SnackbarKey) => {
          // remove this snackbar from redux store
          dispatch(removeSnackbarAction({ key }));
          removeDisplayed(key);
        },
      });

      // keep track of snackbars that we've displayed
      storeDisplayed(key);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notifications, closeSnackbar, enqueueSnackbar, dispatch]);

  return {
    displaySuccessNoti,
    displayErrorNoti,
    displayWarningNoti,
  };
};

export default useNotifier;
