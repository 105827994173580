import * as React from 'react';
import { Box } from '@material-ui/core/';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

export interface FieldContainerProps {
  forceShrink?: boolean;
  className?: string;
  children: React.ReactNode;
}

const useStyles = makeStyles(() => ({
  root: {
    padding: '20px 20px 0px 20px',
  },
}));

const FieldContainer = ({ className, children }: FieldContainerProps) => {
  const classes = useStyles();
  return <Box className={clsx(classes.root, className)}>{children}</Box>;
};

export default FieldContainer;
