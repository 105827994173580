import { RouteConfigWrapper } from '@fabric/ui/src/commons/routeConfigWrapper';
import DelegateAccessIframe from '../DelegateAccess/iframe';

export const UserPath = '/u';
export const UserGroupPath = UserPath + '/user-groups';

const usersRoutes: RouteConfigWrapper[] = [
  {
    route: {
      path: UserPath,
      component: () => <DelegateAccessIframe title="Users" component="groups" />,
    },
  },
];

export default usersRoutes;
