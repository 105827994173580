import TenantList from './tenant.list';
import TenantCreate from './tenant.create';
import TenantEdit from './tenant.edit';
import { RouteConfigWrapper } from '@fabric/ui/src/commons';

const tenantRoutes: RouteConfigWrapper[] = [
  {
    route: { path: '/tenant', exact: true, component: TenantList },
  },
  {
    route: { path: '/tenant/create', exact: true, component: TenantCreate },
  },
  {
    route: { path: '/tenant/edit/:id', exact: true, component: TenantEdit },
  },
];

export default tenantRoutes;
