import React, { Fragment, useState } from 'react';
import Scrollbar from 'react-perfect-scrollbar';
import VerticalNav from '../VerticalNav/VerticalNav';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import useSettings from '../../hooks/useSettings';
// import { Drawer } from '@material-ui/core';

const drawerWidth = 240;

const useStyles = makeStyles(({ ...theme }) => ({
  scrollable: {
    paddingLeft: 20,
    paddingRight: 20,
  },
  sidenavMobileOverlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    width: '100vw',
    zIndex: -1,
    [theme.breakpoints.up('lg')]: {
      display: 'none',
    },
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
}));

interface SidenavProps {
  children?: React.ReactNode;
  navigationSelections?: any;
  navigations?: any;
  settingsComponent?: any;
}

const Sidenav: React.FunctionComponent<SidenavProps> = (props: SidenavProps) => {
  const classes = useStyles();
  const { children, navigations, settingsComponent, navigationSelections } = props;
  const { settings, updateSettings } = useSettings();

  const [open, setOpen] = useState(true);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const updateSidebarMode = (sidebarSettings: any) => {
    const activeLayoutSettingsName = settings.activeLayout + 'Settings';
    const activeLayoutSettings = settings[activeLayoutSettingsName];

    updateSettings &&
      updateSettings({
        ...settings,
        [activeLayoutSettingsName]: {
          ...activeLayoutSettings,
          leftSidebar: {
            ...activeLayoutSettings.leftSidebar,
            ...sidebarSettings,
          },
        },
      });

    setOpen(!open);
  };

  return (
    <Fragment>
      {/*<Drawer*/}
      {/*    variant="permanent"*/}
      {/*    anchor="left"*/}
      {/*    className={clsx(classes.drawer, {*/}
      {/*        [classes.drawerOpen]: open,*/}
      {/*        [classes.drawerClose]: !open,*/}
      {/*    })}*/}
      {/*    classes={{*/}
      {/*        paper: clsx({*/}
      {/*            [classes.drawerOpen]: open,*/}
      {/*            [classes.drawerClose]: !open,*/}
      {/*        }),*/}
      {/*    }}*/}
      {/*>*/}
      <Scrollbar options={{ suppressScrollX: true }} className={clsx('relative px-4', classes.scrollable)}>
        {children}
        {navigationSelections && <VerticalNav items={navigationSelections} />}
        <VerticalNav items={navigations} />
        {settingsComponent && <VerticalNav items={settingsComponent} />}
      </Scrollbar>

      <div onClick={() => updateSidebarMode({ mode: 'close' })} className={classes.sidenavMobileOverlay} />
      {/*</Drawer>*/}
    </Fragment>
  );
};

export default Sidenav;
