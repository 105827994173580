import Mock from '../mock';

const tenantDB = {
  tenants: [
    {
      id: '4567890',
      company_name: 'BNPL',
      user_count: 15,
    },
    {
      id: '4567891',
      company_name: 'Billease',
      user_count: 7,
    },
    {
      id: '4567892',
      company_name: 'Atome',
      user_count: 4,
    },
    {
      id: '4567893',
      company_name: 'Hoolaah',
      user_count: 9,
    },
  ],
};

Mock.onGet('/api/tenant/all').reply(() => {
  return [200, tenantDB.tenants];
});

export default tenantDB;
