import { RouteConfigWrapper, TransactionPath } from '@fabric/ui/src/commons';
import TransactionDelegateAccess from './TransactionDelegateAccess';

const transRoutes: RouteConfigWrapper[] = [
  {
    route: {
      path: TransactionPath,
      exact: true,
      component: () => <TransactionDelegateAccess />,
    },
  },
];

export default transRoutes;
