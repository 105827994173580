import React from 'react';
import clsx from 'clsx';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  info: {
    background: 'linear-gradient(41.53deg, #53AE78 0%, #008BDF 100%)',
  },
  rect: {
    background: 'linear-gradient(118.75deg, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0.3) 100%)',
    borderRadius: 20,
    width: 432,
    height: 320,
  },
  rectW: {
    paddingTop: '173px',
  },
  manage: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '20px',
    lineHeight: '30px',
    color: '#FFFFFF',
  },
  more: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontSize: '13px',
    lineHeight: '20px',
    color: '#FFFFFF',
    opacity: '0.7',
  },
  font: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
  },
  agree: {
    fontWeight: 500,
    fontSize: '12px',
    lineHeight: '18px',
    letterSpacing: '0.2px',
    color: '#90A0B7',
    paddingLeft: '10px',
  },
  slider: {
    width: '200px',
    height: '4px',
    paddingBottom: '180px',
  },
  sliderItem1: {
    width: '32px',
    height: '4px',
    top: '726px',
    background: '#FFFFFF',
    borderRadius: '6px',
  },
  sliderItem2: {
    width: '32px',
    height: '4px',
    top: '726px',
    background: '#FFFFFF',
    opacity: 0.5,
    borderRadius: '6px',
  },
  sliderItem3: {
    width: '32px',
    height: '4px',
    top: '726px',
    background: '#FFFFFF',
    opacity: 0.5,
    borderRadius: '6px',
  },
  checkBox: {
    border: '2px solid #D5D5D5',
    boxSizing: 'border-box',
    borderRadius: '4px',
    height: '16px',
    width: '16px',
    paddingRight: '8px',
  },
}));

const Right: React.FunctionComponent = () => {
  const classes = useStyles();

  return (
    <Grid item lg={6} md={6} sm={6} xs={12}>
      <div className={clsx(classes.info)}>
        <div className={clsx(classes.rectW, 'flex justify-center items-center')}>
          <div className={clsx(classes.rect, 'p-8')} />
        </div>
        <div className={clsx(classes.manage, 'p-8 flex justify-center items-center')}>
          <span>Manage everything in one place</span>
        </div>
        <div className={clsx(classes.more, 'p-8 flex justify-center items-center')}>
          <span>
            Accusam noluisse mel et. Ius duis menandri ne, rebum exerci ad his. Quo at tollit veniam assueverit
          </span>
        </div>
        <div className={clsx(classes.more, 'p-8 flex justify-center items-center')}>
          <Grid container className={clsx(classes.slider)} spacing={2}>
            <Grid item xs={12}>
              <Grid container justifyContent="center" spacing={2}>
                <Grid item>
                  <div className={clsx(classes.sliderItem1)}></div>
                </Grid>
                <Grid item>
                  <div className={clsx(classes.sliderItem2)}></div>
                </Grid>
                <Grid item>
                  <div className={clsx(classes.sliderItem3)}></div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </div>
    </Grid>
  );
};

export default Right;
