import { useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { loginAction, logoutAction } from 'app/redux/authentication/authentication.actions';
import { RootState } from 'app/redux/store';
import {
  completeNewPasswordAction,
  setupTotpAction,
  confirmTotpAction,
  confirmMfaAction,
} from 'app/redux/authentication/authentication.actions';
import { CognitoUser } from 'app/models/cognitoUser';
import { getRedirectionPathFromLoginState, LoginState } from './utils';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const useAuth = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const { loginState, accessToken, user } = useSelector((state: RootState) => state && state.authentication);

  useEffect(() => {
    if (loginState === LoginState.LOGGED_IN && accessToken && user) {
      if (location.pathname === '/' || location.pathname.startsWith('/session')) {
        history.push('/tenant');
      }

      return;
    }

    const redirectionPath = getRedirectionPathFromLoginState(loginState);
    if (location.pathname !== redirectionPath) {
      history.push(redirectionPath);
    }
  }, [loginState, accessToken, user, history, location]);

  const login = useCallback(
    ({ userName, password }) => {
      dispatch(loginAction({ userName, password }));
    },
    [dispatch],
  );

  const logout = useCallback(() => {
    dispatch(logoutAction());
  }, [dispatch]);

  const completePassword = useCallback(
    ({ userName, oldPassword, newPassword }) => {
      dispatch(
        completeNewPasswordAction({
          userName,
          oldPassword,
          newPassword,
        }),
      );
    },
    [dispatch],
  );

  const setupTotp = useCallback(
    (cognitoUser: CognitoUser) => {
      dispatch(setupTotpAction(cognitoUser));
    },
    [dispatch],
  );

  const confirmTotp = useCallback(
    (cognitoUser: CognitoUser, code: string) => {
      dispatch(confirmTotpAction({ user: cognitoUser, totp: code }));
    },
    [dispatch],
  );

  const confirmMfa = useCallback(
    (cognitoUser: CognitoUser, code: string) => {
      dispatch(confirmMfaAction({ user: cognitoUser, mfaCode: code }));
    },
    [dispatch],
  );

  const getUserName = () => {
    const familyName = user?.attributes?.family_name;
    const givenName = user?.attributes?.given_name;

    if (familyName && givenName) {
      return `${familyName} ${givenName}`;
    }
    if (givenName) {
      return givenName;
    }
    if (familyName) {
      return familyName;
    }

    return user?.attributes?.email?.split('@')[0] || '';
  };

  const getUserEmail = () => {
    return `${user?.attributes?.email}`;
  };

  const getAvatarLetter = () => {
    const familyName = user?.attributes?.family_name;
    const givenName = user?.attributes?.given_name;

    if (familyName && givenName) {
      return `${familyName.charAt(0)} ${givenName.charAt(0)}`;
    }
    if (givenName) {
      return givenName.substring(0, 2);
    }
    if (familyName) {
      return familyName.substring(0, 2);
    }

    return user?.attributes?.email?.substring(0, 2);
  };

  return {
    user,
    loginState,
    login,
    logout,
    completePassword,
    setupTotp,
    confirmTotp,
    confirmMfa,
    getUserName,
    getUserEmail,
    getAvatarLetter,
  };
};
export default useAuth;
