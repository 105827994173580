import { account_base_url } from 'variables';
import axios from 'axios';
import { Auth } from 'aws-amplify';

const axiosInstance = axios.create({
  baseURL: account_base_url,
  timeout: 10000,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

async function getToken() {
  try {
    const session = await Auth.currentSession();
    return session.getIdToken().getJwtToken();
  } catch (_e) {
    return null;
  }
}

axiosInstance.interceptors.request.use(
  async function (config) {
    if (!config.headers['authorization']) {
      const token = await getToken();
      if (token) {
        config.headers!['authorization'] = `Bearer ${token}`;
      }
    }

    return config;
  },
  function (error) {
    return Promise.reject(error);
  },
);

export const apiClient = axiosInstance;
