import { useInfiniteQuery } from 'react-query';
import { getTenantList } from '../../services/tenantService';
import { cloneDeep } from 'lodash';

export const TENANT_LIST = 'TENANT_LIST';

const defaultCriteria = [
  {
    field: 'status',
    value: 'active',
  },
];

const defaultPageSize = 20;

export const useTenantSelectionList = (searchValue?: string) => {
  const criteria = cloneDeep(defaultCriteria);

  if (searchValue) {
    criteria.push({
      field: 'name',
      value: searchValue,
    });
  }
  return useInfiniteQuery(
    [TENANT_LIST, searchValue],
    ({ pageParam = 1 }) => {
      return getTenantList({ page_size: defaultPageSize, page: pageParam }, criteria);
    },
    {
      getNextPageParam: (lastPage) => {
        return lastPage.metadata.page + 1;
      },
    },
  );
};
