import axios from 'axios';
import { account_base_url } from 'variables';

export interface EntitySchema {
  type: string;
  required: string[];
  properties: { [key: string]: any };
}

const axiosInstance = axios.create({
  baseURL: account_base_url,
  timeout: 10000,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

export async function getTenantEntities(
  tenantId: string,
  tenantDelegationToken: string,
  excludeTypes: string[],
  pageSize: number = 50,
): Promise<Array<any>> {
  const response = await axiosInstance.get('/v1/tenants/entities', {
    headers: {
      Authorization: `Bearer ${tenantDelegationToken}`,
      account_id: tenantId,
    },
    params: {
      exclude_types: excludeTypes.join(','),
      page_size: pageSize,
    },
  });

  return response.data.data;
}

export async function getEntitiesMetadata(
  tenantId: string,
  tenantDelegationToken: string,
): Promise<Record<string, EntitySchema>> {
  const response = await axiosInstance.get('/v1/tenants/entities/metadata', {
    headers: {
      Authorization: `Bearer ${tenantDelegationToken}`,
      account_id: tenantId,
    },
  });

  const entitiesSchemas: Record<string, EntitySchema> = {};
  const allSchemas = response.data;
  Object.keys(allSchemas).forEach((schemaName) => {
    const schema = flattenEntitySchema(allSchemas[schemaName]);
    // only add Schema that have `type` property, which is Entity type.
    if (schema?.properties?.type?.default) {
      entitiesSchemas[schema.properties.type.default] = schema;
    }
  });

  return entitiesSchemas;
}

export async function encryptSecret(
  plaintext: string,
  tenantId: string,
  tenantDelegationToken: string,
): Promise<string> {
  const response = await axiosInstance.post(
    '/v1/tenants/entities/secrets/encrypt',
    {
      plaintext: plaintext,
      target: 'platform',
    },
    {
      headers: {
        Authorization: `Bearer ${tenantDelegationToken}`,
        account_id: tenantId,
      },
    },
  );

  return response.data.ciphertext;
}

export async function createEntity(entityData: any, tenantId: string, tenantDelegationToken: string): Promise<string> {
  const response = await axiosInstance.post('/v1/tenants/entities', entityData, {
    headers: {
      Authorization: `Bearer ${tenantDelegationToken}`,
      account_id: tenantId,
    },
  });

  return response.data;
}

export async function updateEntity(
  entityId: string,
  entityData: any,
  tenantId: string,
  tenantDelegationToken: string,
): Promise<string> {
  const response = await axiosInstance.put(`/v1/tenants/entities/${entityId}`, entityData, {
    headers: {
      Authorization: `Bearer ${tenantDelegationToken}`,
      account_id: tenantId,
    },
  });

  return response.data;
}

export async function deleteEntity(entityId: string, tenantId: string, tenantDelegationToken: string): Promise<string> {
  const response = await axiosInstance.delete(`/v1/tenants/entities/${entityId}`, {
    headers: {
      Authorization: `Bearer ${tenantDelegationToken}`,
      account_id: tenantId,
    },
  });

  return response.data;
}

function flattenEntitySchema(entitySchema: any): EntitySchema | undefined {
  if (entitySchema?.type === 'object') {
    return entitySchema as EntitySchema;
  }

  if (entitySchema?.allOf) {
    const allOfs = flattenAllOf(entitySchema.allOf);

    const schema: EntitySchema = {
      type: 'object',
      required: [],
      properties: {},
    };

    allOfs.forEach((item: any) => {
      if (item.type === 'object') {
        schema.required = schema.required.concat(item.required || []);
        schema.properties = { ...schema.properties, ...item.properties };
      }
    });

    return schema;
  }

  return undefined;
}

function flattenAllOf(allOf: any[]): any[] {
  return allOf.reduce((acc: any[], item: any) => {
    if (item.allOf) {
      return acc.concat(flattenAllOf(item.allOf));
    }

    return acc.concat(item);
  }, []);
}
