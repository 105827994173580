import { SorterResult } from 'antd/lib/table/interface';
/* eslint-disable @typescript-eslint/no-explicit-any */
import DataGridFilterColumn from '@fabric/ui/src/components/DataGrid/filterColumn';
import { createAction } from '@reduxjs/toolkit';
import { Tenant } from '../../models/tenant';

const getTenantsActionName = 'GET_TENANTS';
const getTenantsMenuActionName = 'GET_TENANTS_MENU';
const editTenantActionName = 'EDIT_TENANT';
const createTenantActionName = 'CREATE_TENANT';
const getTenantDetailsActionName = 'GET_TENANT_DETAILS';
const deleteTenantActionName = 'DELETE_TENANT';

const getTenantsAction = createAction<{
  page_size: number;
  page: number;
  criterias?: DataGridFilterColumn[];
  sorter?: SorterResult<Tenant>[];
}>(getTenantsActionName);

const getTenantsMenuAction = createAction<{
  page_size: number;
  page: number;
  criterias?: DataGridFilterColumn[];
}>(getTenantsMenuActionName);

const createTenantAction = createAction<{ tenant: Tenant; history: any }>(createTenantActionName);

const getTenantDetailsAction = createAction<{ id: string }>(getTenantDetailsActionName);

const editTenantAction = createAction<{ tenant: Tenant; history: any }>(editTenantActionName);

const deleteTenantAction = createAction<{
  tenant: Tenant;
  page_size: number;
  page: number;
  criterias?: DataGridFilterColumn[];
  sorter?: SorterResult<Tenant>[];
}>(deleteTenantActionName);

export {
  getTenantsAction,
  createTenantAction,
  editTenantAction,
  deleteTenantAction,
  getTenantsActionName,
  getTenantsMenuAction,
  getTenantsMenuActionName,
  getTenantDetailsAction,
  getTenantDetailsActionName,
};
