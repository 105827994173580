import layout1Settings from './Layout1/Layout1Settings';
import { themes } from '@fabric/ui';
import TenantSelection from './Layout1/TenantSelection';
import { Features, features_turn_off } from 'variables';
import { CustomerPath, MerchantPath, PatLinkPath, TransactionPath } from '@fabric/ui/src/commons';

export const navigations = [
  {
    name: '',
    type: 'selection',
    component: TenantSelection,
    text: 'Administrator',
    _uid: 'BUY6Drn9e1',
  },
  {
    name: 'Dashboard',
    key: Features.dashboard,
    path: '/dashboard',
    icon: 'dashboard',
    type: 'tenant-delegate',
  },
  {
    name: 'Transactions',
    key: Features.transactions,
    path: TransactionPath,
    icon: 'view_agenda',
    type: 'tenant-delegate',
  },
  {
    name: 'Customers',
    key: Features.customers,
    path: CustomerPath,
    icon: 'person',
    type: 'tenant-delegate',
  },
  {
    name: 'Partners',
    key: Features.merchants,
    path: MerchantPath,
    icon: 'account_balance_wallet',
    type: 'tenant-delegate',
  },
  {
    name: 'Pre-Approved Transactions Links (PAT)',
    key: Features.pat_links,
    path: PatLinkPath,
    icon: 'dataset_linked',
    type: 'tenant-delegate',
  },
  {
    name: 'Reports',
    key: Features.reports,
    path: '/reports',
    icon: 'assessment',
    type: 'tenant-delegate',
  },
  {
    type: 'divider',
  },
  {
    name: 'User Management',
    key: Features.user_management,
    path: '/u',
    icon: 'people',
    type: 'tenant-delegate',
  },
  {
    name: 'Audit Trail',
    key: Features.audit_trail,
    path: '/audit',
    icon: 'assessment',
    type: 'tenant-delegate',
  },
  {
    type: 'divider',
  },
  {
    label: 'Open Fabric',
    type: 'open-fabric',
  },
  {
    name: 'Manage Tenants',
    path: '/tenant',
    icon: 'manage_accounts',
  },
  {
    name: 'Manage Entities',
    path: '/entity',
    icon: 'settings_input_component',
  },
  {
    name: 'Fabric User Audit Trail',
    path: '/audit-osp',
    icon: 'assessment',
  },
].filter((r) => !r.key || !features_turn_off.includes(r.key));

export const LayoutSettings = {
  activeLayout: 'layout1',
  activeTheme: 'green',
  perfectScrollbar: false,
  themes: themes,
  layout1Settings,
  // Footer options
  footer: {
    show: false,
    fixed: false,
    theme: 'white',
  },
  seletedTenantId: '',
  navigations: navigations,
};
