import MockAdapter from 'axios-mock-adapter';
import { apiClient } from 'app/commons/axios';
import userDB from './db/users';

const Mock = new MockAdapter(apiClient);

Mock.onGet('/api/user/all').reply(() => {
  return [200, userDB.users];
});

Mock.onGet('/api/user').reply((config) => {
  const id = config.data;
  const response = userDB.users.find((user) => user.id === id);
  return [200, response];
});

Mock.onPost('/api/user/add').reply((config) => {
  const user = JSON.parse(config.data);
  userDB.users.push(user);
  return [200, userDB.users];
});

export default Mock;
