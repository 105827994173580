/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import Button from '../button/button';
import clsx from 'clsx';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

export interface ConfirmationDialogProps {
  classes?: Record<'paper', string>;
  id?: string;
  value?: string;
  open: boolean;
  children?: React.ReactNode;
  title?: string;
  onSubmit?: (value?: string) => void;
  onCancel?: (value?: string) => void;
}

const useConfirmationDialogStyles = makeStyles(() => ({
  btn: {
    margin: '0 5px',
  },
  cancelBtn: {
    color: '#1c1b1b',
    background: '#c2c1c1',
  },
}));

const ConfirmationModal: React.FunctionComponent<ConfirmationDialogProps> = ({
  onSubmit,
  value: valueProp,
  onCancel,
  open,
  classes,
  children,
  title,
  ...props
}: ConfirmationDialogProps) => {
  const confirmationClasses = useConfirmationDialogStyles();

  const handleCancel = () => {
    onCancel && onCancel();
  };

  const handleOk = () => {
    onSubmit && onSubmit(valueProp);
  };

  return (
    <Dialog maxWidth="xs" aria-labelledby="confirmation-dialog-title" open={open} className={clsx(classes)} {...props}>
      <DialogTitle id="confirmation-dialog-title">{title}</DialogTitle>
      <DialogContent dividers>{children}</DialogContent>
      <DialogActions>
        <Grid container justify="center">
          {onCancel && (
            <Button
              type="button"
              onClick={handleCancel}
              className={clsx(confirmationClasses.btn, confirmationClasses.cancelBtn)}
            >
              Cancel
            </Button>
          )}
          {onSubmit && (
            <Button type="button" onClick={handleOk} className={confirmationClasses.btn}>
              Ok
            </Button>
          )}
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationModal;
