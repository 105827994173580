import * as React from 'react';
import { useLocation } from 'react-router-dom';

export const useQueryParams = <
  Params extends { [key: string]: string | string[] | undefined } = {
    [key: string]: string | undefined;
  },
>() => {
  const location = useLocation();

  const params = React.useMemo(() => {
    const urlSearchParams = new URLSearchParams(location.search);
    return Object.fromEntries(urlSearchParams as any);
  }, [location.search]);

  return params as Params;
};
