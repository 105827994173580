import NotFound from './NotFound';
import CognitoLogin from './CognitoLogin';
import CompletePassword from './CompletePassword';
import MfaSetup from './MfaSetup';
import MfaChallenge from './MfaChallenge';

const routes = [
  {
    path: '/session/signin',
    component: CognitoLogin,
  },
  {
    path: '/session/complete-password',
    component: CompletePassword,
  },
  {
    path: '/session/mfa-setup',
    component: MfaSetup,
  },
  {
    path: '/session/mfa-challenge',
    component: MfaChallenge,
  },
  {
    path: '/session/404',
    component: NotFound,
  },
];

export default routes;
