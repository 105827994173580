import { useQuery } from 'react-query';
import DataGridFilterColumn from '@fabric/ui/src/components/DataGrid/filterColumn';
import { Paging } from '../../models/paging';
import { getAuditLogs } from '../../services/auditService';
import { SorterResult } from 'antd/lib/table/interface';
import { AuditLogV2 } from 'app/models/audit';

export const SYSTEM_LOG_LISTING_KEY = 'SYSTEM_LOG_LISTING';
export const AUDIT_LOG_LISTING_KEY = 'AUDIT_LOG_LISTING';

export const useAuditLogListing = (
  pagination: Paging,
  criterias?: DataGridFilterColumn[],
  sort?: SorterResult<AuditLogV2>[],
): any => {
  return useQuery(
    [AUDIT_LOG_LISTING_KEY, pagination, criterias, sort],
    () => getAuditLogs(pagination, criterias, sort),
    {
      keepPreviousData: true,
    },
  );
};
