import Mock from '../mock';

const transactionDB = {
  transactions: [
    {
      id: '#4567890',
      payment_id: '#4567890',
      card_number: '**** **** **67',
      customer: '#2456789',
      status: 'In Process',
      amount: '$456',
    },
    {
      id: '#4567891',
      payment_id: '#4567891',
      card_number: '**** **** **67',
      customer: '#2456789',
      status: 'Done',
      amount: '$456',
    },
    {
      id: '#4567892',
      payment_id: '#4567892',
      card_number: '**** **** **67',
      customer: '#2456789',
      status: 'Cancelled',
      amount: '$456',
    },
    {
      id: '#4567893',
      payment_id: '#4567890',
      card_number: '**** **** **67',
      customer: '#2456789',
      status: 'Cancelled',
      amount: '$456',
    },
    {
      id: '#4567894',
      payment_id: '#4567890',
      card_number: '**** **** **67',
      customer: '#2456789',
      status: 'In Process',
      amount: '$456',
    },
    {
      id: '#4567895',
      payment_id: '#4567890',
      card_number: '**** **** **67',
      customer: '#2456789',
      status: 'Done',
      amount: '$456',
    },
  ],
};

Mock.onGet('/api/txn/all').reply(() => {
  return [200, transactionDB.transactions];
});

Mock.onGet('/api/txn').reply((config) => {
  const id = config.data;
  const response = transactionDB.transactions.find((txn) => txn.payment_id === id);
  return [200, response];
});

Mock.onPost('/api/txn/add').reply((config) => {
  const txn = JSON.parse(config.data);
  transactionDB.transactions.push(txn);
  return [200, transactionDB.transactions];
});

export default transactionDB;
