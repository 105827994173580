import React, { useEffect, useState } from 'react';
import { Card, Grid, Button, CircularProgress, Box } from '@material-ui/core';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { makeStyles } from '@material-ui/core/styles';
import useAuth from 'app/hooks/auth/useAuth';
import clsx from 'clsx';
import Right from './Right';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { useTranslation } from 'react-i18next';
import useNotifier from 'app/hooks/useNotifier';
import useUI from 'app/hooks/useUi';
import { completeNewPasswordActionName } from 'app/redux/authentication/authentication.actions';

const useStyles = makeStyles(() => ({
  cardHolder: {
    background: '#F5F6F8',
  },
  card: {
    width: 1440,
    height: 850,
    borderRadius: 12,
    margin: '1rem',
  },
  info: {
    background: 'linear-gradient(41.53deg, #53AE78 0%, #008BDF 100%)',
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  btnSignIn: {
    background: '#29A370',

    boxShadow: '0px 4px 10px rgba(16, 156, 241, 0.24)',
    borderRadius: 4,
    width: '100%',
    fontWeight: 600,
    fontSize: '13px',
    lineHeight: '20px',

    textAlign: 'center',
    letterSpacing: ' 0.1px',
    height: '48px',
    /* Neutral / White */

    color: '#FFFFFF',
  },
  left: {
    paddingTop: '97px',
    paddingLeft: '80px',
    paddingRight: '80px',
  },
  txtSignIn: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '32px',
    lineHeight: '48px',
    display: 'flex',
    alignItems: 'center',
    color: '#192A3E',
  },
  font: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
  },
  warning: {
    fontSize: '14px',
    lineHeight: '18px',
    paddingLeft: '32px',
  },
}));

const CompletePassword: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const [completePasswordInfo, setCompletePasswordInfo] = useState({
    oldPassword: '',
    newPassword: '',
    confirmPassword: '',
  });
  const [message, setMessage] = useState('');
  const { completePassword } = useAuth();
  const { getRequestByName } = useUI();

  useEffect(() => {
    ValidatorForm.addValidationRule('isPasswordMatch', (value: string) => {
      if (completePasswordInfo.newPassword.length > 0 && completePasswordInfo.newPassword !== value) {
        return false;
      }
      return true;
    });
  }, [completePasswordInfo]);

  const { user } = useSelector((state: RootState) => state && state.authentication);

  const classes = useStyles();
  const { displayErrorNoti } = useNotifier();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleChange = (props: { target: any }) => {
    const temp = { ...completePasswordInfo };
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    // eslint-disable-next-line react/prop-types
    temp[props.target.name] = props.target.value;
    setCompletePasswordInfo(temp);
  };

  const handleFormSubmit = async () => {
    try {
      completePassword({
        userName: user?.email,
        oldPassword: completePasswordInfo.oldPassword,
        newPassword: completePasswordInfo.newPassword,
      });
    } catch (error: unknown) {
      setMessage(`${error}`);
      displayErrorNoti(`${error}`);
    }
  };

  return (
    <div className={clsx('flex justify-center items-center  min-h-full-screen', classes.cardHolder)}>
      <Card className={classes.card}>
        <Grid container>
          <Grid item lg={6} md={6} sm={6} xs={12}>
            <div className={classes.left}>
              <div className="p-8 flex justify-center items-center">
                <img className="w-200 p-2" src="/logo_dark.png" alt="" />
              </div>

              <Box display="flex" justifyContent="center" className={classes.txtSignIn}>
                {t('welcomeToOpenFabric')}
              </Box>

              <Box paddingTop="10px">
                <Box display="flex" justifyContent="center" className={clsx(classes.warning, classes.font)}>
                  {t('firstTimeLoginMsg')}
                </Box>
                <Box
                  display="flex"
                  justifyContent="left"
                  className={clsx(classes.warning, classes.font)}
                  paddingTop="15px"
                >
                  Your password must follow these rules :
                </Box>
                <Box
                  display="flex"
                  justifyContent="left"
                  className={clsx(classes.warning, classes.font)}
                  paddingTop="10px"
                  marginLeft="20px"
                >
                  - minimum 8 characters length
                </Box>
                <Box
                  display="flex"
                  justifyContent="left"
                  className={clsx(classes.warning, classes.font)}
                  marginLeft="20px"
                  paddingTop="10px"
                >
                  - require numbers
                </Box>
                <Box
                  display="flex"
                  justifyContent="left"
                  className={clsx(classes.warning, classes.font)}
                  marginLeft="20px"
                  paddingTop="10px"
                >
                  - require special characters
                </Box>
                <Box
                  display="flex"
                  justifyContent="left"
                  className={clsx(classes.warning, classes.font)}
                  marginLeft="20px"
                  paddingTop="10px"
                >
                  - require uppercase letters
                </Box>
                <Box
                  display="flex"
                  justifyContent="left"
                  className={clsx(classes.warning, classes.font)}
                  marginLeft="20px"
                  paddingTop="10px"
                >
                  - require lowercase letters
                </Box>
              </Box>
              <div className="p-8 h-full bg-light-gray relative">
                <ValidatorForm onSubmit={handleFormSubmit}>
                  <TextValidator
                    className="mb-3 w-full"
                    label={t('previousPassword')}
                    variant="outlined"
                    onChange={handleChange}
                    name="oldPassword"
                    type="password"
                    value={completePasswordInfo.oldPassword}
                    validators={['required']}
                    errorMessages={[t('requiredFieldErrMsg')]}
                  />

                  <TextValidator
                    className="mb-3 w-full"
                    label={t('newPassword')}
                    variant="outlined"
                    onChange={handleChange}
                    name="newPassword"
                    type="password"
                    value={completePasswordInfo.newPassword}
                    validators={['required']}
                    errorMessages={[t('requiredFieldErrMsg')]}
                  />

                  <TextValidator
                    className="mb-3 w-full"
                    label={t('confirmNewPassword')}
                    variant="outlined"
                    onChange={handleChange}
                    name="confirmPassword"
                    type="password"
                    value={completePasswordInfo.confirmPassword}
                    validators={['isPasswordMatch', 'required']}
                    errorMessages={[t('passwordMismatch'), t('requiredFieldErrMsg')]}
                  />
                  {message && <p className="text-error">{message}</p>}

                  <div className="flex flex-wrap items-center mb-4">
                    <Button
                      variant="contained"
                      className={clsx(classes.btnSignIn, classes.font)}
                      color="primary"
                      disabled={
                        getRequestByName({
                          name: completeNewPasswordActionName,
                        })?.loading
                      }
                      type="submit"
                    >
                      {t('changePassword')}
                    </Button>
                    {getRequestByName({
                      name: completeNewPasswordActionName,
                    })?.loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                  </div>
                </ValidatorForm>
              </div>
            </div>
          </Grid>
          <Right />
        </Grid>
      </Card>
    </div>
  );
};

export default CompletePassword;
