import { SnackbarKey } from 'notistack';
import { createAction } from '@reduxjs/toolkit';
import { Notification } from '../../models/notification';

const enqueueSnackbarActionName = 'ENQUEUE_SNACKBAR';
const enqueueSuccessActionName = 'ENQUEUE_SUCCESS';
const enqueueErrorActionName = 'ENQUEUE_ERROR';
const enqueueWarningActionName = 'ENQUEUE_WARNING';
const closeSnackbarActionName = 'CLOSE_SNACKBAR';
const removeSnackbarActionName = 'REMOVE_SNACKBAR';

const enqueueSnackbarAction = createAction<{ notification: Notification }>(enqueueSnackbarActionName);

const closeSnackbarAction = createAction<{ key: SnackbarKey }>(closeSnackbarActionName);

const removeSnackbarAction = createAction<{ key: SnackbarKey }>(removeSnackbarActionName);

const enqueueSuccessAction = createAction<{ msg: string }>(enqueueSuccessActionName);

const enqueueErrorAction = createAction<{ msg: string }>(enqueueErrorActionName);

const enqueueWarningAction = createAction<{ msg: string }>(enqueueWarningActionName);

export {
  enqueueSnackbarAction,
  closeSnackbarAction,
  removeSnackbarAction,
  enqueueSuccessAction,
  enqueueErrorAction,
  enqueueWarningAction,
};
export { enqueueSnackbar, removeSnackbar } from './notifier.slice';
